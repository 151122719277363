@import '../../../../styles/variables';
@import '../../../../styles/typography';

.line-chart-container {
    display: flex;
    flex: 1;
}

.role-breakdown {
    &__x-axis {
    }
    &__custom-tooltip {
        min-width: 200px;
        padding: 0.5rem;
        color: $secondary;
    }

    &__custom-tooltip-title {
        padding-bottom: 1rem;
    }
    
    &__custom-tooltip-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.75rem;
    }
    
    &__custom-tooltip-label {
        display: flex;
        flex: 4;
        flex-direction: row;
        justify-content: flex-start;

        &.mtnm {
            color: $secondary;
            opacity: $opacity-other1;
        }

        &.mtnm::after {
            display: inline-block;
            content: '*';
        }
    }

    &__custom-tooltip-line {
        content: '';
        width: 0.75rem;
        margin-right: 0.5rem;
        height: 0px;
        border: 1px;
    }

    &__custom-tooltip-value {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        font-weight: $semiBold;

        &.mtnm::after {
            display: inline-block;
            content: '';
            height: 2px;
            width: 0.5rem;
            margin-right: 0.5rem;
            background-color: $secondary;
            opacity: $opacity-other1;
        }
    }

    &__custom-tooltip__mntm-note {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        color: $secondary;
        opacity: $opacity-other1;
    }

    &__tooltip-comparison-type {
        text-transform: capitalize;
    }
    
}




