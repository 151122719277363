@import "../../styles/variables";

.error-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  h1 {
    color: $primary;
    font-size: 5rem;
  }

  p {
    color: $grey-text;
    margin: 20px auto 30px;
  }
}
