@import '../../../styles/variables';
@import '../../../styles/typography';

.custom-hover-tooltip-popper {
    .custom-hover-tooltip {
        background-color: white;
        border: 2px solid $primary;
        border-radius: 8px;
        color: $secondary;
        font-weight: $regular;
        font-family: 'Lato';
        font-size: 12px;
        padding: 16px;
        white-space: pre-wrap;

        &--arrow {
            color: $white;

            &:before {
                border: 2px solid $primary;
                backgroundColor: $white;
                width: calc(100% - 1px);
                height: calc(100% - 1px);
            }
        }

    }

    &[x-placement*="top"] {
        .custom-hover-tooltip--arrow {
            &::before {
                transform-origin: 0 calc(100% + 2px);
            }
        }
    }

    &[x-placement*="top"] {
        .custom-hover-tooltip--arrow {
            &::before {
                transform-origin: calc(100% + 2px) 0;
            }
        }
    }
}
