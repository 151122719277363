@import '../../../styles/variables';
@import '../../../styles/typography';
@import '../../../styles/layout';
@import '../styles/';

.certification-card {
    border: 1px solid $primary;
}

.certification-detail {
    box-sizing: content-box;
    min-height: $min-card-height;
    display: flex;
    

    &__card-title {
        color: $secondary;
        opacity: $opacity-primary;
    }

    &__award-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: calc(100% - #{$card-padding-vertical});
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        flex: 0.2;
    }

    &__award-img {
        flex: 0.5;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
            flex-shrink: 0;
            height: 100px;
        }
        
    }

    &__award-img-cert {
        flex: 0.8;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
            flex-shrink: 0;
            height: 150px;
        }
        
    }

    &__signal-text {
        flex: 0.2;
        font-size: 12px;
        font-family: 'LatoWeb';
        color: $secondary;
    }

    &__contact-us {
        padding-top: 0.5rem;

        .MuiButton-root {
            background-color: $primary;
            color: $white;
            text-transform: capitalize;
        }
        .MuiButton-text {
            font-family: 'LatoWeb';
        }
    }

    &__learn-more,
    &__download-media {
        .MuiButton-root {
            background-color: $white;
            color: $primary;
            text-transform: capitalize;
        }
        .MuiButton-text {
            font-family: 'LatoWeb';
        }
    }

    &__download-media {
        flex: 0.2;
    }

    &__unilever {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img {
            width: 80%;
        }
    }
}
