/* Colors */
$primary: #6221EA;
$secondary: #322352;
$alternate: #EBECF0;
$light_bg: #F5F6F8;
$grey-text: #918383;
$white: #FFFFFF;
$black: #000000;
$link: #874FFB;
$error: #E53935;
$warning: #FBC02D;
$success: #00C853;
$app_bg: #E5E5E5;
$black: #000000;
$executive-summary-bg: rgba(98, 33, 234, 0.05);
$diversity-vc-red: #ff2d2d;
$grey: rgba(50, 35, 82, 0.68);

/* DS Colors */
$blue00: #e9f7fbff;
$blue10: #ddf1f8ff;
$blue20: #bde9f8ff;
$blue30: #9bdef4ff;
$blue40: #7ad3f1ff;
$blue50: #59c8edff;
$blue60: #47a0beff;
$blue70: #35788eff;
$blue80: #24505fff;
$blue90: #12282fff;
$blueSharp: #6ee4f9ff;
$fuchsia00: #fceaf4ff;
$fuchsia10: #fcdeeeff;
$fuchsia20: #f3aed2ff;
$fuchsia30: #ed85bbff;
$fuchsia40: #e75da5ff;
$fuchsia50: #e1348eff;
$fuchsia60: #b42a72ff;
$fuchsia70: #871f55ff;
$fuchsia80: #5a1539ff;
$fuchsia90: #2d0a1cff;
$fuchsiaSharp: #ff6196ff;
$green00: #d9f9ecff;
$green10: #d9f9ecff;
$green20: #b4f3dbff;
$green30: #8eecc5ff;
$green40: #69e6b1ff;
$green50: #43e09eff;
$green60: #36b37eff;
$green70: #28865fff;
$green80: #1b5a3fff;
$green90: #0d2d20ff;
$greenSharp: #47f5acff;
$lime00: #fafce6ff;
$lime10: #edf2c2ff;
$lime20: #edf4b9ff;
$lime30: #e5ef96ff;
$lime40: #dce973ff;
$lime50: #d3e450ff;
$lime60: #a9b640ff;
$lime70: #7f8930ff;
$lime80: #545b20ff;
$lime90: #2a2e10ff;
$limeSharp: #defa50ff;
$lobster00: #fbe0dfff;
$lobster10: #fbe0dfff;
$lobster20: #f8c0bfff;
$lobster30: #f4a19fff;
$lobster40: #f1817fff;
$lobster50: #ed625fff;
$lobster60: #be4e4cff;
$lobster70: #8e3b39ff;
$lobster80: #5f2726ff;
$lobster90: #2f1413ff;
$lobsterSharp: #d92f2bff;
$orange00: #fcefeaff;
$orange10: #fae8e1ff;
$orange20: #f4bda9ff;
$orange30: #ee9c7eff;
$orange40: #e97b53ff;
$orange50: #e35a28ff;
$orange60: #b64820ff;
$orange70: #883618ff;
$orange80: #5b2410ff;
$orange90: #2d1208ff;
$orangeSharp: #ff8b61ff;
$primary10: #f9f7ffff;
$primary20: #f2eeffff;
$primary30: #c9bcf8ff;
$primary40: #8361ffff;
$primary50: #5b34e9ff;
$primary60: #4726bdff;
$primary70: #3c2478ff;
$primary80: #2f1c5eff;
$primary90: #231052ff;
$primaryGray1: #f4f5f8ff;
$primaryGray2: #eeeff2ff;
$primaryWhite: #ffffffff;
$purple00: #f8e8fcff;
$purple10: #f4dcfcff;
$purple20: #e5aef6ff;
$purple30: #d985f2ff;
$purple40: #cc5dedff;
$purple50: #bf34e9ff;
$purple60: #9324aeff;
$purple70: #731f8cff;
$purple80: #4c155dff;
$purple90: #230c28ff;
$purpleSharp: #da61ffff;
$secondary100: #ddd8f0ff;
$secondary200: #c5c0d6ff;
$secondary300: #aeabb8ff;
$secondary400: #8b839eff;
$secondary500: #6b6185ff;
$secondaryTransparent: #ffffff00;
$secondaryTransparent2: #3c247833;
$tangerine00: #fbf4e9ff;
$tangerine10: #f7ebd4ff;
$tangerine20: #f7deb1ff;
$tangerine30: #f2cd8aff;
$tangerine40: #eebd63ff;
$tangerine50: #eaac3cff;
$tangerine60: #bb8a30ff;
$tangerine70: #8c6724ff;
$tangerine80: #5e4518ff;
$tangerine90: #2f220cff;
$tangerineSharp: #ffd761ff;

/* Opacity levels */
$opacity-primary: 0.87;
$opacity-other1: 0.54;
$opacity-other2: 0.38;
$iln-primary: #27829E;
$iln-dark: #434967;
$red: red;

/* Home and Analyze V2*/
$data-card-min-width: 818px;
$data-card-max-width: 1100px;

@mixin recharts-tooltip-override {
    .recharts-tooltip-wrapper {
        transition: none !important;
     }

     .recharts-tooltip-wrapper:empty {
         display: none;
     }

     .recharts-default-tooltip {
         border: 2px solid $primary !important;
         border-radius: 4px;
         font-size: 14px;
     }

     .custom-tooltip {
         background-color: $white;
         border: 2px solid $primary;
         border-radius: 4px;
         font-size: 14px;
         padding: 0.5rem;

         &__percent {
             margin: 0,
         }
         &__name {
             margin: 0;
             color: $secondary;
             opacity: $opacity-other1;
         }
     }
}
