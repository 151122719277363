@import '../../../../styles/variables';
@import '../../../../styles/typography';

.div_comp_detail {
    flex: 1;
    display: flex;
    flex-direction: column;

    margin-left: 32px;
    padding-left: 32px;
    border-left: 1px solid rgba(50, 35, 82, 0.1);

    &__header_row {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: 2em;
    }

    &__data_row {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(50, 35, 82, 0.14);
    }

    &__data_row_last {
        border-bottom: none;
    }

    &__label_col {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__type_col {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center !important;
        color: $primary;
        font-weight: $semiBold !important;
    }
    
    &__top_label_col {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center !important;
    }

    &__company_name,
    &__industry_avg {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    &__data_col {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

    &__data_circle {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid;
        height: 42px;
        width: 42px;
        border-radius: 28px;
    }

    &__arrow {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 1em;
    }

    &__pnts {
        border-color: #D0BEF7;
    }

    &__ipnts {
        border-color: #B6BFF6
    }

    &__nd {
        border-color: #9169ED;
    }

    &__ind {
        border-color: #536BEC;
    }

    &__d {
        border-color: #6221EA;
    }

    &__id {
        border-color: #1032E4;
    }

    &__arrow_img {

    }

    &__tooltip_heading_text {
        font-size: 0.75rem;
        text-align: left;
    }

    &__tooltip_list {
        list-style: none;
        padding-left: 0;
    }

    &__tooltip_bullet {
        list-style-type: none;
        font-size: 0.75rem;
        text-align: left;
    }

    &__undefined {
        background-color: #ebecf0;
        border: 2px dashed rgba(50,35,82,0.38);
        h5 {
            display: none;
        }
    }

    &__industry_avg {
        text-transform: capitalize;
    }

    &__small-text {
        font-size: 0.75rem;
    }
}


