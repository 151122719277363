@import '../../../styles/variables';

#password {
  h2 {
    margin: 0 0 24px;
    color: $primary90;
  }

  p {
    font-family: "LatoWebLight";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140.62%;
    color: #322352;
  }

  a {
    font-weight: bold;
    color:#6221EA;
    border-bottom: 1pt solid #6221EA;
  }
}