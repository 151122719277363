@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-news {
    text-align: left;
    margin-left: 40px;

    &-card {
        height: 566px !important;
        margin-top: 47px;
        margin-right: 0;
        margin-left: 0;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 0;
        padding-top: 12px;
        height: 491px;
        overflow-y: auto;
    }

    h2 {
        font-family: LatoWebBold;
        color: $iln-dark;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .iln-nav {
        margin: 0px !important;
        border-bottom: 1px solid rgba(39, 130, 158, 0.38) !important;
        margin-bottom: 20px !important;

       .nav-item {
           .selected {
               border-bottom: 3px solid $iln-primary !important;
           }
            

            a {
                font-family: LatoWebBold;
                color: $iln-dark !important;
                font-size: 1.25rem;
                opacity: 1;
            }   
        }
    }
}

.news-container {
    width: 100%;

    h3 {
        font-family: LatoWebSemibold;
        font-size: 14px;
        color: $iln-dark;
        margin-bottom: 10px;
    }

    .news-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    
        h5 {
            color: $iln-dark;
            font-family: LatoWeb;
            font-size: 12px
        }
    }

    a {
        font-family: LatoWebBold;
        font-size: 14px;
        color: $iln-primary !important;
        text-decoration: underline !important; 
    }
}

.news-site {
    color: $iln-primary !important;
}

.news-loading {
    display: flex;
    width: 100%;
    height: 80%;
    justify-content: center;
    align-items: center;
}
