@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/layout";

#survey-progress {
  background-color: #eeeeff;
  position: fixed;
  top: 77px;
  width: 100%;
  right: 0;
  height: 100px;
  display: flex;
  align-items: center;
  z-index: 6;

  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 750px;
    min-height: 45px;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 24px;
      margin-top: 6px;
      white-space: nowrap;
    }

    li.progress-step {
      color: $secondary;
      position: relative;
      justify-content: flex-start;
      width: 100%;
      text-align: center;
      margin-left: 0;
      margin-top: 0;
      font-weight: $regular;

      &:not(:first-child) {
        &::after {
          margin-left: -50%;
          width: 100%;
          border-top: 2px solid white;
          position: absolute;
          content: '';
          bottom: -2px;
        }
      }

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        background: white;
        position: absolute;
        border-radius: 50%;
        bottom: -9px;
        margin: 0 auto;
        z-index: 99;
        align-self: center;
      }

      &.selected {
        &::before {
          background: $primary;
          filter: drop-shadow(0px 0px 8px rgba(98, 33, 234, 0.68));
        }

        &::after {
          border-top: 2px solid $primary;
        }
      }
      p {
        position: relative;

      }
    }
  }

  .disabled-link {
    pointer-events: none;
    opacity: 0.5;
  }
}

#survey {
  height: 100%;

  .loader-container {
    z-index: 5;
  }

  .survey-review {
    height: 100%;
  }

  #survey-init,
  #survey-generic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .survey-content-container {
      max-width: 450px;
      text-align: center;

      h5 {
        color: $secondary;
        font-size: 24px;
      }

      p {
        color: $secondary;
        font-weight: $regular;
        font-size: 14px;
        margin-bottom: 24px;
      }

      .btn {
        padding-left: 36px;
        padding-right: 36px;
      }
    }
  }

  #survey-generic {
    margin-top: 60px;
  }

  .survey-setup {
    &__content-container {
      margin-top: 143px;
      background-color: white;
      padding: 43px 111px;
      width: 700px;
      box-shadow: 0px 0px 1.8px rgba(0, 0, 0, 0.38);
      border-radius: 4px;

      h5 {
        text-align: center;
        margin-bottom: 40px;
      }

      ol {
        list-style-type: decimal;
        padding-left: 0;

        > li:not(:last-child) {
          margin-bottom: 32px;
        }

        li {
          list-style-type: none;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .description-tooltip {
              width: 20px;
              cursor: help;
            }
          }

          ul {
            padding-left: 0;

            label {
              margin-left: 7px;
            }
          }

          .question-title {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .accordion {
    width: 100%;
    padding: 0px 47px;

    .card {
      border: none;
      padding: 0;
      box-shadow: 0px 0px 1.65463px rgba(0, 0, 0, 0.38);

      .intro-content {
        color: $secondary;
        font-weight: $light;
      }

      &-header {
        background-color: white;
        border-bottom: 0;
        padding: 28px 45px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: $secondary;

        img {
          width: 12px;
          height: 12px;
          transition: 0.3s ease-in-out transform;
          transform: rotate(180deg);
        }

        &.open {
          img {
            transform: rotate(0deg);
          }
        }
      }

      &-body {
        padding: 0;
        border-top: 1px solid rgba(98, 33, 234, 0.38);
      }


      .show ~ .card-header {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .copy {
    height: calc(100% - 4px);
    position: absolute;
    right: 0;
    width: 60px;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    cursor: pointer;
    background: white;
    margin: 2px;
    border-radius: 2px;

    p {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #6221EA;
      pointer-events: none;
    }
  }
  .survey-create {
      .datatable {
        width: 100%;

        @include filterTabs;

        th {
          max-width: 150px;

          &:after {
            border-right: 30px solid #f4f5f8;
          }
        }

        &-content {
          width: 100%;
          background-color: white;
          flex-direction: column;
          padding: 65px 0;
        }
      }

      &__content-container {
      margin-top: 60px;
      padding: 20px;
      width: 100%;

      &.preview {
         margin-top: 0;

        .datatable-content {
          padding: 0;
        }
      }

      &__description {
        padding: 28px 48px 15px;
        color: $grey;
        font-size: 13px;
      }

      &__questions {
        background-color: white;
        padding-left: 0;
        width: 100%;

        .question {
          &.has-error {
            border: 4px solid $red;
            padding: 20px 40px 48px;

            .global-survey-message {
              margin-bottom: 20px;
            }

            .container {
              padding: 0;
            }
          }
        }
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $secondary;
        border-bottom: 1px solid $primary;
        padding: 77px 0;
        margin-bottom: 55px;

        p, li {
          font-size: 16px;
          line-height: 24px;
        }

        li:not(:last-child) {
          margin-bottom: 10px;
        }

        &-content-container {
          width: 780px;
          margin-right: 225px;
        }

        .note-container {
          background-color: white;
          border: 1px solid #6221EA;
          border-radius: 4px;
          padding: 25px 30px;

          ul {
            padding-left: 15px;
            list-style-type: decimal;
          }
        }
      }
    }
  }

  #survey-live-content {
    margin-top: 60px;
    padding: 70px 120px;

    .accordion {
      margin-top: 40px !important;

      .card-header {
        .toggle-container {
          pointer-events: none;

          small,
          .icon {
            pointer-events: none;
          }

          .icon-caret {
            margin-left: 10px;
          }
        }

        .toggle-hide {
          display: none;
        }

        &.open {
          .toggle-view {
            display: none;
          }

          .toggle-hide {
            display: contents;
          }
        }
      }

      &:last-child {
        margin-top: 30px !important;
      }
    }

    .survey-templates {
      padding: 20px;

      .survey-template {
        padding: 25px 35px;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        .email-heading {
          color: $primary;
          margin-bottom: 30px;
        }

        .copy {
          top: 10px;
          align-items: flex-start;
          height: auto;
        }
      }
    }


    .accordion {
      padding: 0;
      margin-top: 30px;

      .card-header {
        padding: 28px 18px;

        .icon {
          pointer-events: none;
        }
      }

      .datatable-filters {
        th:before,
        th:after {
          border-right: 30px solid white;
          width: calc(100% + 0px)
        }
      }
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }

    .refresh-icon {
      width: 15px;
      cursor: pointer;
      margin-top: 20px;

      &.is-loading {
        animation: rotation 2s infinite linear;
      }
    }

    .card-fh {
      height: 100%;
    }

    .close-survey-container {
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    .close-survey {
      align-self: center;
      width: 260px;
      position: relative;
      height: 38px;

      .loader-container {
        background: transparent;
        height: 20px;
        width: 20px;
        right: 117px;
        top: 8px;

        img {
          width: 20px;
        }
      }
    }

    .closing-note {
      font-size: 12px;
      width: 260px;
      text-align: center;
      margin: 0 auto
    }

    .nav {
      @include navtabs;
      margin-bottom: 14px;
    }

    .copy-link-container {
      position: relative;

      .collector-link {
        border: 1px solid #CED4DA;
        border-radius: 4px;
        width: 100%;
        padding: 14px;
      }
    }
    .submitted-response-card {
      display: flex;
      align-items: center;

      .employee-count {
        font-size: 16px;
        margin-bottom: -5px;
        margin-left: 3px;
      }

      h5  {
        color: $secondary;
      }
      p {
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $secondary;
      }

      .total_responses_container {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      .responses-highlight {
        border: 2px solid #EBECF0;
        border-radius: 50%;
        height: 121px;
        width: 121px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 34px;
        line-height: 41px;
        color: $secondary;
        opacity: 0.87;
        flex-direction: column;
      }
    }
  }

  .percent-complete {
    font-size: 8px;
    color: $secondary;
    font-weight: bold;
    margin-top: 10px
  }

  .progress-arc {
    border: 3px solid #21ac62;
    border-radius: 50%;
    width: 122px;
    height: 122px;
    position: absolute;
    clip-path: polygon(50% -20.71%, 50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);

    &.p-0 {
      clip-path: polygon(50% -20.71%, 50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    &.p-10 {
      clip-path: polygon(50% -20.71%, 50% 50%, 90% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    &.p-20 {
      clip-path: polygon(50% -20.71%, 50% 50%, 120.71% 30%, 120.71% 50%, 120.71% 50%, 120.71% 50%, 100% 0%);
    }

    &.p-30 {
      clip-path: polygon(50% -20.71%, 50% 50%, 100% 65%, 100% 100%, 100% 100%, 100% 100%, 100% 0%);
    }

    &.p-40 {
      clip-path: polygon(50% -20.71%, 50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 0%);
    }

    &.p-50 {
      clip-path: polygon(50% -20.71%, 50% 50%, 50% 120.71%, 50% 120.71%, 50% 120.71%, 100% 100%, 100% 0%);
    }

    &.p-60 {
      clip-path: polygon(50% -20.71%, 50% 50%, 20% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%);
    }

    &.p-70 {
      clip-path: polygon(50% -20.71%, 50% 50%, -20.71% 75%, -20.71% 50%, 0% 100%, 100% 100%, 100% 0%);
    }

    &.p-80 {
      clip-path: polygon(50% -20.71%, 50% 50%, -50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
    }

    &.p-90 {
      clip-path: polygon(50% -20.71%, 50% 50%, 0% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
    }

    &.p-100 {
      clip-path: polygon(50% -20.71%, 50% 50%, 50% -20.71%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
    }
  }
}

#saved-surveys {
  h5 {
    margin: 28px 0 18px 10px;
  }
  .card {
    padding: 0;

    table {
      margin-bottom: 0;

      &.table-link {
        tr {
          td {
            width: 33.33%;
          }
        }
      }

      tr:first-child {
        td {
          border-top: 0px;
        }
      }
    }

    &-body {
      padding: 0;
    }
  }
}

.survey-header .global-survey-errors {
  .global-survey-message {
    margin-top: 5px;

    &:first-child {
      margin-top: 76px;
    }
  }
}

.datatable-content > .global-survey-message {
  padding: 0 40px;
}
