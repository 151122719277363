@import '../../../styles/variables';
@import '../../../styles/layout';


.dashboard-tooltip {
    &__clickable {
        font-size: inherit;
        cursor: pointer;
        color: $secondary !important;
    }

    &__content {
        max-width: 300px;
        padding: 0.5rem;
        font-size: 0.75rem;
        color: $secondary;
    }
}

.dashboard-tooltip-custom-class {
    border: 2px solid $primary !important;
    opacity: 1 !important;
  }
  .dashboard-tooltip-custom-class.place-top::after,
  .dashboard-tooltip-custom-class.place-bottom::after {
      border-color: $primary transparent !important;
  }
  .dashboard-tooltip-custom-class.place-right::after,
  .dashboard-tooltip-custom-class.place-left::after {
    border-color: transparent $primary !important;
  }

#bespoke-heat-map {
    padding: 0 1rem;
    position: relative;

    hr {
        margin-bottom: 60px;
    }

    .export-excel-btn {
        font-size: 13px;
        position: absolute;
        z-index: 999;
        right: 34px;
        margin-top: 18px;

        i {
            margin-right: 5px;
        }
    }

    .overview_title {
        height: 20px;
        text-align: left;
        padding: 0;
        margin: 0;

        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
    }

    .datatable-container {
        overflow-x: auto;
    }

    .datatable {
        @include filterTabs;

        &__scrollable-table-head {
            // does not scroll, but identical right-hand offset to table-body
            // if scrollbar appears
            display: block;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        &__scrollable-table-body {
            max-height: 500px;
            box-sizing:border-box;
            display: block;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        &__dg-tooltip-labels {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        &__dg-tooltip-description {
            margin-top: 10px;
            text-align: left;
        }

        td:first-child, th {
            font-weight: 500;
            font-size: 12px;
            color: #322352;
            text-transform: capitalize;
        }

        tr {
            display: flex;
        }

        th, td:first-child {
            background-color: rgba(235, 236, 240, 0.68);
        }

        th, td {
            width: 100px;
            min-height: 70px;
            padding: 0.25rem !important;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            border-bottom: 1px solid rgba(50, 35, 82, 0.28);
            border-right: 1px solid rgba(50, 35, 82, 0.28);
            text-align: center;

            &:first-child {
                border-left: 1px solid rgba(50, 35, 82, 0.28);
            }
        }

        th {
            border-top: 1px solid rgba(50, 35, 82, 0.28);

            p {
                margin: 0 15px 0 -3px;
                font-size: 10px;
            }
        }

        .green {
            background-color: rgba(0, 200, 83, 0.18);
        }

        .red {
            background-color: rgba(229, 57, 53, 0.18);
        }

        .home-heat-map__cell_main * {
            font-size: 1rem;
        }

        &-border {
            height: 6px;
            background: $primary;
            margin-right: 1px;
        }

        &-metric {
            th {
                border-top: 0;
            }
        }

        .empty-cell {
            border: none;
            background: none;
            //pointer-events: none;
            cursor: default;
            opacity: 1;

            &:before,
            &:after {
                border: none;
            }
        }
    }
}

#heat-map span {
    margin-right: 0;
}

#heat-map .legend {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: lighter;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    justify-content: flex-end;
    align-items: center;
    height: 24px;
    margin-top: 40px;
}

#heat-map .legend .strength {
    padding: 0 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#heat-map .legend .painpoint {
    padding: 0 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#heat-map .legend .na {
    padding: 0 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#heat-map .legend .strength:before {
    content: url(strength.svg);
    padding-right: 5px;
}

#heat-map .legend .painpoint:before {
    content: url(painpoint.svg);
    padding-right: 5px;
}

#heat-map .legend .na:before {
    content: url(/assets/images/nid.svg);
    padding-right: 5px;
    padding-bottom: 3px;
}

#heat-map .help-tooltip {
    color: #bdb8bd;
    padding-top: 5px;
    text-align: center;
}

#xLabel-cell .help-tooltip {
    width: 100%;
    text-align: right;
}
