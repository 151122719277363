@import '../../../styles/variables';
@import '../../../styles/typography';

.employees-feedback {

    &__barchart_header {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__subtitle {
        font-size: 16px;
        font-weight: $regular;
        color: $secondary;
        opacity: 0.68;
        width: 765px;
        line-height: 19px;
        margin: 14px 0;
    }

    &__painpoint-label {
        font-weight: $semiBold;
        font-size: 12px;
        line-height: 14px;
        color: $secondary;
    }

    &__container {
        margin-top: 40px;

        hr {
            border-top: 1px solid $alternate;
            opacity: 0.68;
        }
    }

    &__categories {
        overflow-y: hidden;
        white-space: nowrap;
        text-align: left;
        margin-top: 25px;

        &__category-button {
            min-width: 60px;
            display: inline-block;
            height: 29px;
            outline: none;
            cursor: pointer;
            background: $alternate;
            color: rgba(50, 35, 82, 0.68);
            margin: 0 6px 12px 6px;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            border: none;
            padding: 0 14px;
            box-sizing: border-box;
            border-radius: 37px;

            &:first-child {
                margin-left: 0;
            }

            &.selected{
                background: $primary;
                color: white;
            }
        }
    }

    &__comments,
    &__barchart {
        &__label {
            font-weight: $semiBold;
            font-size: 12px;
            line-height: 14px;
            color: $secondary;
            margin-top: 34px;

            span {
                color: $primary;
            }
        }

        &__container {
            width: 640px;
            margin: 0 auto;
            margin-top: 32px;
            overflow-y: scroll;
            max-height: 540px;

            .feedback-card {
                border: 1px solid $primary;
                background: #FFFFFF;
                padding-top: 20px;
                padding-bottom: 20px;

                .flex {
                    height: 100%;
                }

                &__icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 34px;

                    .icon {
                        width: 20px;
                        height: 20px;
                        background: #EBECF0;
                    }
                }

                &__comment-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow-y: auto;
                    padding-right: 32px;
                    height: 100%;
                }

                &:not(last-child) {
                    margin-bottom: 32px;
                }

                &:nth-child(odd) {
                    border-radius: 5px 40px 40px 40px;
                }

                &:nth-child(even) {
                    border-radius: 40px 40px 5px 40px;
                }
            }
        }
    }

    &__barchart {
        &__container {
            width: 100%;
            .recharts-tooltip-wrapper {
                display: none;
            }
        }
    }

    &__barchart-legend {
        display: flex;
        margin: 30px 0;

        .barchart-legend-demographic {
            display: flex;
            align-items: center;
            margin-top: 40px;

            h3 {
                text-transform: capitalize;
                min-width: 50px;
                max-width: 50px;
                color: $primary;
                font-family: LatoWeb;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .barchart-legend-table {
            margin: 0 50px 30px;

            .freetext-table {
                min-width: 600px;
                max-width: 600px;

                tr td {
                    height: auto !important;
                    width: auto !important;
                }

                &__header {
                    height: 100px;
                    min-height: 90px !important;
                    
                    .freetext-cell {
                        padding-bottom: 15px !important;
                        height: 100%;
                        min-width: 50px !important;
                        max-width: 50px !important;
                    }
                }

                &__pain-cell {
                    width: 270px;
                }

                .cell-style__bold {
                    font-family: LatoWeb;
                    font-size: 12px;
                    font-weight: bold;
                    color: $secondary
                }

                .cell-style__medium {
                    font-family: LatoWeb;
                    font-size: 12px;
                    font-weight: 500;
                    color: $secondary;
                }

                .MuiTableCell-root {
                    border-color: rgba(98, 33, 234, 0.38);
                }
            }

            .box {
                height: 15px;
                width: 15px;
                border-radius: 5px;
            }

            .gender_primary {
                background-color: #CFBCF8;
            }

            .gender_secondary {
                background-color: #6221EA;
            }

            .ethnicity_primary {
                background-color: #B6BFF6;
            }

            .ethnicity_secondary {
                background-color: #0725E8;
            }

            .sexual_orientation_primary {
                background-color: #F8ABD6;
            }

            .sexual_orientation_secondary {
                background-color: #DE425B;
            }

            .disability_primary {
                background-color: #FBC02D;
            }

            .disability_secondary {
                background-color: #FF811D;
            }
        }
    }
}

#feedback .overview_title {
    text-align: left;
    padding: 0;
    margin: 0;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}


#feedback .feedback-container .filter-container .all-selected-button {
    border: none;
    cursor: pointer;
    margin-right: 5px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #6221EA;
}

#feedback .feedback-container .filter-container .all-button {
    border: none;
    border-bottom: none;
    cursor: pointer;
    margin-right: 5px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6221EA;
}

#feedback .feedback-container .filter-container .networks-button {
    height: 30px;
    width: 150px;
    outline: none;
    cursor: pointer;
    margin: 0 5px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6221EA;

    border: 3px solid #6221EA;
    box-sizing: border-box;
    border-radius: 50px;
}

#feedback .feedback-container .filter-container .communications-button {
    height: 30px;
    width: 150px;
    outline: none;
    cursor: pointer;
    margin: 0 5px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #0725E8;

    border: 3px solid #0725E8;
    box-sizing: border-box;
    border-radius: 50px;
}

#feedback .feedback-container .filter-container .diversity-button {
    height: 30px;
    width: 150px;
    outline: none;
    cursor: pointer;
    margin: 0 5px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FF2455;

    border: 3px solid #FF2455;
    box-sizing: border-box;
    border-radius: 50px;
}

#feedback .feedback-container .filter-container .win-button {
    height: 30px;
    width: 150px;
    outline: none;
    cursor: pointer;
    margin: 0 5px;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #66686A;

    border: 3px solid #66686A;
    box-sizing: border-box;
    border-radius: 50px;
}

#feedback .feedback-container .feedback-scroll {
    height: 500px;
    width: 100%;
    overflow-y: scroll;
}

#feedback-card-networks {
    width: 95%;
    margin: 2% 0;

    background: #FFFFFF;
    border: 3px solid #6221EA;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 40px 5px;
}

#feedback-card-diversity {
    width: 95%;
    margin: 2% 0;

    background: #FFFFFF;
    border: 3px solid #FF2455;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 40px 5px;
}

#feedback-card-communications {
    width: 95%;
    margin: 2% 0;

    background: #FFFFFF;
    border: 3px solid #0725E8;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 40px 5px;
}

#feedback-card-wins {
    width: 95%;
    margin: 2% 0;

    background: #FFFFFF;
    border: 3px solid #66686A;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 40px 5px;
}

.feedback-container h2 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
}

.feedback-container p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.44px;
    text-align: left;
}

.feedback-container .push-pin-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}
