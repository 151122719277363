@import '../../../styles/variables';
@import '../../../styles/typography';

@include recharts-tooltip-override;

.perf-time-custom-tooltip {
    min-width: 160px;
    padding: 0.5rem;

    &__title {
        padding-bottom: 1rem;
    }
    
    &__row {
        display: flex;
        flex-direction: row;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        font-size: 0.75rem;
    }
    
    &__label_score,
    &__label_industry {
        display: flex;
        flex: 4;
        flex-direction: row;
        justify-content: flex-start;
    }

    &__label_industry {
        text-transform: capitalize;
    }

    &__label_score::before,
    &__label_industry::before {
        content: '';
        width: 1rem;
        height: 0.4rem;
        float: left;
        margin-right: 0.5rem;
    }

    &__label_score::before {
        border-bottom: 2px solid $primary;
    }

    &__label_industry::before {
        border-bottom: 2px dashed #CFB4F7; 
    }

    &__value {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
    }


}

.perf-time {
    &__subtitle {
        padding-bottom: 2rem;
        font-size: 0.75rem;
    }

    &__legend-wrap,
    &__charting-wrapper {
        display: flex;
        flex-direction: row;
    }

    &__line-chart,
    &__legend {
        flex: 6;
    }

    &__right-panel,
    &__right-spacer {
        flex: 2;
    }
}
