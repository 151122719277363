@import "../../styles/variables";

.auth-form {
	height: 100vh;
	width: 100vw;
	background: #fafbfc;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.logo {
		margin: 30px 0;
		width: 130px;
	}

	&__forgot-help-text {
		text-align: center;
		margin: 0px 30px;
	}

	&__text-field {
		width: 250px;
	}

	&__email-note {
		font-size: 0.75em;
	}

	&__success-message {
		margin-bottom: 1rem;
	}

	&__request-link-text {
		margin: 0px 30px;
		text-align: center;
	}

	.text-center {
		text-align: center;
	}

	a {
		color: $link;
	}

	.login {
		z-index: 2;
	}

	.login__btn {
		margin-top: 15px;
		background: $primary;
		//min-width: 280px;
	}

	.error-container {
		margin: 25px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.error-container .error {
		color: red;
	}

	.forgot-password-button,
	.forgot-password-button:hover {
		text-transform: none;
		font-family: "Lato", sans-serif;
		font-size: 16px;
		margin: 25px 0;
		background-color: transparent;
	}

	&__login-with-sso {
		margin-top: 12px;
		font-size: 16px;
	}
}

.register-form {
	&__text-field {
		min-width: 300px;
		overflow: visible;
		margin: 8px;
	}

	&__email-field {
		.MuiFormLabel-root {
			font-size: 0.88rem !important;
		}
	}

	&__email-note {
		font-size: 0.75rem;
	}
}
