@import '../../../styles/variables';

.filters-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0 50px 0;

    .filter-actions {
        text-align: right;

        .apply-filter,
        .clear-filter {
            margin: 16px 0px;
            background: #6221EA;
            border-radius: 4px;
            color: white;
            width: 96px;
            height: 36px;
            border:none;
            cursor: pointer;
            font-size: 14px;

            &.apply-filter {
                margin: 0 0 0 20px;
            }

            &.clear-filter {
                background: none;
                width: auto;
                color: #6221EA;
                margin: 0 0 0 6px;
            }
        }
    }
}

.selected-filters {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #322352;
    font-size: 12px;
    margin-top: 32px;
    padding: 1rem;
    background-color: #ebecf0;
    border-radius: 5px;

    &__top-row {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
    }
    &__label {
        color: #6221EA;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: stretch;
        font-weight: bold;
    }
    &__filter-row {
        line-height: 1.5rem;
    }
    &__button {
        margin: 0;
        padding: 0;
        font-size: 0.75rem;
        background-color: transparent;
        border: none;

        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
    }

    &__caret-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    $caret-size: 0.4rem;

    &__caret-down {
        display: inline-block;
        content: "";
        border-top: $caret-size solid $primary;
        border-right: $caret-size solid transparent;
        border-bottom: 0;
        border-left: $caret-size solid transparent;
    }

    &__caret-up {
        display: inline-block;
        content: "";
        border-bottom: $caret-size solid $primary;
        border-right: $caret-size solid transparent;
        border-top: 0;
        border-left: $caret-size solid transparent;
    }

    &__type {
        text-transform: capitalize;
        font-weight: bold;
    }
}
