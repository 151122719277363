.bar-chart .chart-container {
    height: 250px;
    width: 100%;
    overflow: hidden;
}

.bar-chart .y-axis-label {
    position: relative;
    float: left;
    height: 100%;
    width: 70px;
    /*border: 1pt solid black;*/
    box-sizing: border-box;
}

.bar-chart .y-axis-label > div {
    top: 50%;
    position: relative;
    transform: translateY(-50%) rotate(-90deg);
    width: 140px;
    text-align: center;
    right: 25px;
}

.bar-chart .x-axis-label {
    margin-left: 60px;
    text-align: center;
}

.bar-chart {
    display: inline-block;
    width: 50%;
    height: 100%;
    margin-bottom: 50px;
    vertical-align: top;
}

.bar-chart .recharts-wrapper {
    margin: 0px;
    float: left;
}
