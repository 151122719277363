@import '../../../../styles/variables';
@import '../../../../styles/typography';

.diversity-snapshot-pie-chart {
    flex: 1;
    cursor: pointer;

    &__pie {
        cursor: pointer;
        outline: none;
    }

    &__label {
        font-size: 0.88rem;
        font-weight: $medium;
        color: $secondary;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &.selected-label {
            color: $primary;
        }
    }

    &__legend-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__all-labels {
        display: inline-block;
        margin-top: 10px;
        width: 80%;
    }

    &__pie-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: left;
        font-size: 12px;
        color: $secondary;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &__pie-label-dot-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__pie-label-dot {
        content: '';
        background: rgba(94, 119, 255, 0.14);
        height: 8px;
        width: 8px;
        float: left;
        display: block;
        border-radius: 100%;
        margin-right: 0.5rem;
    }

    &__label-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        color: $secondary;
    }

    &__score {
        font-weight: $semiBold;

        &.gray {
            width: 25px;
            height: 4px;
            background: url(/assets/images/nid.svg) no-repeat center;
        }
    }

}
