@import '../../../../styles/variables';
@import '../../../../styles/layout';

#inclusion_breakdown-filters__container {

    .MuiTypography-body1,
    .MuiTypography-root,
    .dropdown-toggle,
    .nav-link {
        font-family: 'LatoWeb';
    }

    .filters-container {
        display: block;
        margin-bottom: 22px;
        position: relative;

        .filter-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-btns {
                margin-top: 90px;
                position: absolute;
                right: 0;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
                color: $secondary;
            }
        }
    }

    .inclusion_breakdown-filters__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .inclusion_breakdown-filters {
        min-height: 150px;

        .MuiSvgIcon-root {
            fill: $primary;
        }
        
        .select-box {
            min-width: 300px;

            .MuiInput-input {
                font-size: 15px;
            }

            .MuiSelect-icon {
                color: black;
            }
        }

        .tab-pane.active {
            display: flex;
            flex-direction: column;
        }

        .nav {
            @include navtabs;
            margin-bottom: 32px;
        }

        .tab-pane {
            .row {
                width: 100%;
                display: block;

                .options-column {
                    display: inline-block;
                    width: 33.33%;
                    white-space: nowrap;

                    p {
                        font-size: 12px;
                    }

                    .option-label {
                        padding-bottom: 0;
                        margin-bottom: 0;

                        span {
                            font-size: 14px;
                        }
                    }

                    &-hide {
                        display: none;
                    }
                }
            }
        }

        .dropdown {
            &-toggle {
                cursor: pointer;
                padding-bottom: 15px;
            }

            &-menu {
                padding: 0;
                max-height: 350px;
                overflow-x: scroll;
                background: white;
            }
        }
    }
}

