.dds-diversity-filter-panel {
  flex: 1;
  min-height: 0;
  padding-bottom: 36px;
  overflow-y: auto;
  background: #F4F5F8;
  border-right: 1px solid #FFFFFF;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.08);
}

.dds-diversity-filter-panel-default-view {
  flex: 1;
  min-height: 0;
}

