/** @format */

.recommended-programs-container-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  .recommended-programs-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;

    .recommended-programs-content {
      display: flex;
      flex-direction: column;
      width: 81%;
      max-width: 1080px;

      .dds-programs-logo {
        margin: 0 12px;
      }
      
      .rp-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        margin-top: 40px;
        margin-left: 10px;
        .rp-title-buttons {
          display: flex;
          flex-direction: row;
        }
      }

      .rp-header {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 67px;
        max-width: 1080px;
        margin-top: 40px;
        .temp-header {
          height: 260px;
          width: 100%;
        }
      }

      .rp-carousel {
        width: 100%;
      }

      .rp-programs-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 18px;
        margin-left: 15px;

        .rp-header-text {
          width: 100%;

          p {
            margin-top: 6px;
          }
          .rp-text-body {
            .rp-top-line {
              display: flex;
            }

            h1 {
              font-family: "Plus Jakarta Sans";
              font-style: normal;
              font-weight: 600;
              font-size: 25px;
              line-height: 32px;
              /* identical to box height */

              display: flex;
              align-items: center;

              /* Primary - Plum */

              color: #322352;
            }

            h3 {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              display: flex;
              align-items: center;

              /* Secondary/Secondary_500 */

              color: #6b6185;
            }
          }
          .rp-see-all {
            display: flex;
            justify-content: flex-end;
            margin-right: 15px;
          }
        }

        .rp-header-dropdown {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
        }
      }

      .rp-programs {
        width: 100%;

        .programs {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.recommended-program-wrapper {
  margin-bottom: 10px;
}

.recommended-modal-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 59%;
  transform: translate(-50%, -50%);
  padding: 2px;
  width: 80%;
  max-width: 1155px;
  z-index: 1;
  @media screen and (max-width: 962px) {
    margin-left: 30px;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1360px) {
      align-items: center;
      flex-direction: column;
    }

    .modal-goal-card {
      width: 60%;
      @media screen and (max-width: 1360px) {
        width: 620px;
        margin-bottom: 15px;
      }
    }

    .modal-score-card {
      width: 35%;
      @media screen and (max-width: 1360px) {
        width: 470px;
      }
    }
  }
  .toast-container {
    position: absolute;
    margin-top: 6.5%;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    animation: 0.4s;
  }
}