@import 'variables';
@import 'typography';

/* Card */
$card-padding-horizontal: 26px;
$card-padding-vertical: 21px;
$card-margin: 10px;
$card-shadow: 0px 0px 1.8px rgba(0, 0, 0, 0.38);
$card-border-radius: 15px;
$card-box-shadow: 6px 12px 30px rgba(0, 0, 0, 0.06);

@mixin default-margins {
    margin: $card-margin;
    border-radius: $card-border-radius;
    padding: $card-padding-horizontal;
}

@mixin card {
    padding: $card-padding-vertical $card-padding-horizontal;
    margin: $card-margin;
    background: $white;
    -webkit-box-shadow: $card-shadow;
    box-shadow: $card-shadow;
    border-radius: $card-border-radius;
}

@mixin arrow-up ($background: transparent, $border: 1px, $border-color: transparent) {
    content: '';
    width: 10px;
    height: 10px;
    background: $background;
    transform: rotate(45deg);
    border-top: $border solid $border-color;
    border-left: $border solid $border-color;
}

@mixin navtabs {
    border-bottom: 1px solid rgba(98, 33, 234, 0.38) !important;

    .nav-item {
        flex-grow: unset !important;

        &:not(:first-child) {
            .nav-link {
                margin-left: 28px !important;
            }
        }

        .nav-link {
            color: #322352 !important;
            opacity: 0.68;
            outline: none;
            font-size: 16px;
            font-weight: $medium;
            padding-left: 0 !important;
            padding-right: 0 !important;
            border: 1px solid transparent !important;

            &:hover {
                border-color: transparent !important;
            }

            &.active {
                background-color: transparent !important;
                border-bottom: 3px solid $primary !important;
                opacity: 1;
            }
        }
    }
}

@mixin filterTabs {

    td:first-child, th {
        font-weight: 500;
        font-size: 12px;
        color: #322352;
    }

    tr {
        display: flex;
    }

    th, td:first-child {
        background-color: rgba(235, 236, 240, 0.68);
    }

    th, td {
        width: 100px;
        min-height: 130px;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        border-bottom: 1px solid rgba(50, 35, 82, 0.28);
        border-right: 1px solid rgba(50, 35, 82, 0.28);
        text-align: center;

        &:first-child {
            border-left: 1px solid rgba(50, 35, 82, 0.28);
        }
    }

    th {
        border-top: 1px solid rgba(50, 35, 82, 0.28);
    }

    .green {
        background-color: rgba(0, 200, 83, 0.18);
    }

    .red {
        background-color: rgba(229, 57, 53, 0.18);
    }

    &-filters {
        th.empty-cell {
            min-height: 0;
        }
        th.tab {
            position: relative;
            height: 48px;
            min-height: 48px;
            border-right: none;
            border-top: none;
            border-left: 1px solid rgba(50, 35, 82, 0.28);
            border-bottom-color: transparent;
            color: #322352;
            opacity: 0.58;
            cursor: pointer;
            border-top-left-radius: 4px;
            overflow: hidden;
            box-shadow: 0px 0.9px 0px inset rgba(50, 35, 82, 0.28);
            padding-right: 25px !important;

            &.bespoke {
                justify-content: center;
                font-size: 11px;
            }

            &:after {
                content: "";
                position: absolute;
                height: 95px;
                width: 37px;
                background-color: white;
                right: -23px;
                transform: rotateZ(-36deg);
                border-left: 1px solid rgba(50, 35, 82, 0.28);
                cursor: default;
            }

            &.selected {
                opacity: 1;
                background: $primary;
                color: white;
                border-top: 0;

                &:after {
                    border-left: 1px solid $primary;
                }
            }
        }
    }

    &-border {
        height: 6px;
        background: $primary;
        margin-right: 1px;
    }
}
