@import '../../../styles/variables';
@import '../../../styles/typography';

.focus-areas {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__wrapper {
        width: 90%;
    }
}

.focus-area {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__title {
        padding-bottom: 1.5rem;

        h3 {
            color: $secondary;
            font-weight: $regular;
        }
    }

    &__score {
        padding-bottom: 1.5rem;
    }

    &__numerator {
        font-size: 34px;
        color: $primary;
    }

    &__denominator {
        font-size: 24px;
        color: $secondary;
    }

    &__description {
        h4 {
            font-family: "LatoWeb";
            white-space: pre-wrap;
            font-weight: $regular !important;
        }
    }
}
