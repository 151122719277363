@import '../../../styles/variables';
@import '../../../styles/typography';

.analyze-diversity-snapshot {
    &__detail-box {
        border: 1px solid $primary;
        min-height: 144px;
        margin: 1rem;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;

    }

    &__filter-box {
        margin-left: 1rem;
        margin-right: 1rem;
        display: flex;
        flex: row;
        justify-content: flex-start;
        font-size: 0.75rem;
        color: $primary;
    }

    &__pichart-wrapper {
        display: flex;
        flex-direction: row;
    }

    &__single-pie {
        flex: 1;
    }   

}

.analyze-diversity-snapshot .pie-chart-container {
    display: flex;

}

.analyze-diversity-snapshot .pie-chart-container .pie-container .piechart-placeholder {
    margin-top: 85px;
}
