@import "styles/variables";
@import "styles/typography";
@import "styles/global";

html,
body {
  margin: 0;
  padding: 0;
  font-family: "LatoWeb";
  font-size: 16px;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.clickable {
  cursor: pointer;
}

.dds-navbar {
  transition: width 0.25s ease;
  z-index: 999;

  &--collapsed {
    .companies-logo {
      height: auto;
    }
  }

  a {
    white-space: nowrap;
  }
}
