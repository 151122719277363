@import '../../../styles/variables';

.intro-actions {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 62px;

  .edit-btn {
    font-weight: bold;
    cursor: pointer;

    i {
      margin-right: 5px;
    }
  }

  small {
    color: $grey;
  }

  .btn {
    padding: 2px 14px;
    margin-left: 10px;
  }
}

.question {
  &:not(:last-child) {
    border-bottom: 1px solid $primary;
  }

  padding: 40px 48px;

  .question-title {
    margin-bottom: 34px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span[contenteditable=true] {
      border: 1px dashed transparent;
      padding: 0 8px;

      &:hover {
        border: 1px dashed $primary;
      }
    }

    .description-tooltip {
      width: 20px;
      cursor: help;
    }
  }

  .asterisk {
    display: none;
    color: #d93733;
    margin-left: 4px;
  }

  &.required {
    .asterisk {
      display: inline-block;
    }
  }
}

.intro-question {
  padding: 20px 48px 40px;

  &[contenteditable="true"] {
    border: 1px solid $primary;
  }

  ol {
    padding-left: 15px;

    li {
      padding-left: 10px;
    }
  }
}

.rating-question {
  &__rate-container {
    background-color: black;
    display: flex;
    background-color: $alternate;
    height: 48px;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin-top: 80px;

    p {
      position: absolute;
      top: -45px;
      font-size: 12px;
      color: $secondary;

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }

    .icon-star {
      background-color: white;
      height: 37px;
      width: 37px;

      &.selected {
        background-color: $primary;
      }
    }
  }
}

.ot-question {
  &__text-container {
    textarea {
      border: 1px solid #CED4DA;
      border-radius: 3.44139px;
      width: 80%;
      height: 150px;
      padding: 14px 9px;
    }
  }
}

.sc-question {
  &__choice-container {
    ul {
      padding-left: 0;

      .delete-btn {
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px 0 0;

        .icon-cross {
          width: 10px;
          height: 10px;
          background: $white;
          cursor: pointer;
        }
      }

      .icon {
        width: 13px;
        height: 13px;
      }

      li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        label {
          padding: 2px 6px;
          width: 280px;
          margin-left: 6px;

          &[contentEditable='true'] {
            border: 1px solid #CED4DA;
            border-radius: 4px;
            background-color: white;
          }
        }

        .input-box {
          padding: 2px 6px;
          width: 70%;
          margin-left: 6px;
          border: 1px solid #CED4DA;
          border-radius: 4px;
          background-color: white;

          &:focus {
            outline: none;
          }

          &.error {
            border-color: red;
          }
        }

        .description-tooltip {
          margin-left: 14px;
          cursor: help;
        }

        .error-message {
          color: red;
          margin-left: 15px;
        }

        &.others {
          label {
            border: 1px solid #CED4DA;
            border-radius: 4px;
            background-color: white;

            &.no-border {
              border: none;
            }
          }
        }
      }
    }
  }
}
