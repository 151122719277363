.slack .slack-token-help{
    margin-top: 95px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.slack .slack-token-help > * {
    display: inline-block;
    width: 50%;
    text-align: center;
}


.slack .slack-token-help > div > * {
    margin-bottom:30px;
    text-align: left;
}

.slack .slack-token-help a {
    font-weight: bold;
    color: #6221EA;
    border-bottom: 1pt solid #6221EA;
}