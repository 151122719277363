@import "../../../../styles/variables";

#legend-container {
  .group-legend {
     &-a,
     &-b {
       font-size: 12px;
       color: $secondary;
       margin-top: 16px;
       margin-left: 20px;
       position: relative;
     }

    &-a {
      &::before {
        content: '';
        width: 12px;
        height: 4px;
        background: $primary;
        position: absolute;
        margin-top: 6px;
        margin-left: -20px;
      }
    }

    &-b {
      margin-bottom: 6px;
      &::before {
        content: '';
        width: 12px;
        height: 4px;
        background: #CFBCF8;
        position: absolute;
        margin-top: 6px;
        margin-left: -20px;
      }
    }
  }
}
