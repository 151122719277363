@import '../../../../styles/variables';
@import '../../../../styles/typography';

.perf-time-detail-panel {
        height: 100%;
        margin-left: 2rem;
        padding-left: 2rem;
        border-left: 1px solid rgba(50, 35, 82, 0.1);

        display: flex;
        flex-direction: column;
        justify-content: center;

        &__container {

        }
    
        &__header_row {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    
        &__data_row {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    
        &__label_col {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    
        &__type_col {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            text-align: center !important;
            color: $primary;
            font-weight: $semiBold !important;
        }
        
        &__top_label_col {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            text-align: center !important;
        }
    
        &__company_name,
        &__industry_avg {
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
    
        &__data_col {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
        }
    
        &__data_circle {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid;
            height: 42px;
            width: 42px;
            border-radius: 28px;
        }
    
        &__industry-score {
            border: 2px dashed #D0BEF7;
        }
    
        &__company-score {
            border-color: $primary;
        }
    
        &__undefined {
            background-color: #ebecf0;
            border: 2px dashed rgba(50,35,82,0.38);
            h5 {
                display: none;
            }
        }
    
        &__industry_avg {
            text-transform: capitalize;
        }

        &__date {
            color: $primary;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }


