.oauth .slack{
    margin-top: 100px;
}


.oauth .slack a{
    border-bottom: 1pt solid #6221EA;
    color: #6221EA;
    font-weight: bold;
}

.oauth .slack * {
    margin-top: 20px;
}