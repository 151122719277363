@keyframes placeHolderShimmer{
    0%{
        background-position: -150px 0
    }
    100%{
        background-position: 150px 0
    }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: #eeeeee;
    background-size: 800px 104px;
    min-height: 50px;
    position: relative;
}

.animated-background.loading {
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 30%, #eeeeee 60%);
}

.background-masker {
    background: #fff;
    position: absolute;
}

.piechart-placeholder {
    height: 175px;
    width: 100px;
}

.piechart-placeholder {
    margin: 85px 66px;
}

.piechart-placeholder .background-masker.title-top {
    top: 100px;
    height: 15px;
    left: 0;
    width: 100%;
}

.piechart-placeholder .background-masker.title-bottom {
    top: 135px;
    height: 15px;
    left: 0;
    width: 100%;
}

.piechart-placeholder .background-masker.legend-top {
    top: 155px;
    height: 5px;
    left: 0;
    width: 100%;
}

.piechart-placeholder .background-masker.legend-bottom {
    top: 165px;
    height: 5px;
    left: 0;
    width: 100%;
}

/* Every thing below this is just positioning */

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
    top: 0;
    left: 40px;
    right: 0;
    height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: 8px;
    width: 10px;
}

.background-masker.header-bottom {
    top: 18px;
    height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
    top: 24px;
    height: 6px;
}


.background-masker.header-right,
.background-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0;
}

.background-masker.subheader-right {
    left: 230px;
}

.background-masker.subheader-bottom {
    top: 30px;
    height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 6px;
}

.background-masker.content-top {
    height:20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end{
    width: auto;
    left: 380px;
    right: 0;
    top: 60px;
    height: 8px;
}

.background-masker.content-second-line  {
    top: 68px;
}

.background-masker.content-second-end {
    left: 420px;
    top: 74px;
}

.background-masker.content-third-line {
    top: 82px;
}

.background-masker.content-third-end {
    left: 300px;
    top: 88px;
}

.background-masker.round-thumb {
    height: 100px;
    width: 100px;
    left: 0;
    top: 0;
    background: radial-gradient(circle closest-side at center, rgba(0,0,0,0) 100%, #fff);
}

.pieplaceholder-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
}