@import '../../../../styles/variables';
@import '../../../../styles/typography';

.perf-time-legend {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;

    margin-top: 2rem;

    &__company {
        padding-left: 1rem;
    }

    &__industry-average {
        padding-left: 1rem;
        text-transform: capitalize;
    }

    &__industry-average::before,
    &__company::before {
        content: '';
        width: 1rem;
        height: 0.4rem;
        float: left;
        margin-left: 1rem;
        margin-right: 0.5rem;
    }

    &__company::before {
        border-bottom: 2px solid $primary;
    }

    &__industry-average::before {
        border-bottom: 2px dashed #CFB4F7; 
    }    
}
