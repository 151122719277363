/** @format */

.all-programs-container-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;

  .filter-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 81%;
    margin: 20px;
    max-width: 1067px;

    .search-box-wrapper {
      margin-right: 10px;
    }

    .filters-dropdown-container {
      display: flex;
      gap: 16px;
    }

    .dds-text-field {
      width: 232px;
    }
  }

  .table-body-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    .table-body-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      .solution-cell-wrapper {
        width: 81%;
        margin-bottom: 10px;
        max-width: 1080px;
      }

      .programs {
        display: flex;
        justify-content: center;
      }

      &.no-data {
        margin-bottom: 0;
      }
    }
  }

  .pagination-footer-container {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;

    .right-side {
      display: flex;
    }

    .dds-table-pagination {
      width: 100%;
      background-color: white;
    }
  }

  .all-programs-toast-component {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    animation: 0.4s ease-out 0s 1 slideInFromLeft;
  }
  .all-programs-chip-container {
    display: flex;
    width: 81%;
    padding-bottom: 12px;
    max-width: 1080px;
  }
}
