@import '../../../../styles/variables';
@import '../../../../styles/typography';

.role-breakdown {
    &__legend {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
    
        &.legend-group {
            padding-right: 1rem;
        }
    
        &.legend-line {
            content: '';
            width: 0.75rem;
            height: 2px;
            margin-right: 0.5rem;
        }  
    }
}
