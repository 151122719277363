@import '../../../styles/variables';
@import '../../../styles/layout';
  
.iln-calendar {
    display: flex;
    justify-content: space-between;
    height: 750px;
    width: 100%;
    margin-bottom: 47px;

    h2 {
        color: $iln-dark;
        font-family: LatoWebBold;
        font-size: 20px;
    }
    

    .iln-calendar-card-close {
        height: 100%;
        width: 80%;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 47px;
        transition: 1s ease;
    }

    .iln-calendar-card-open {
        height: 100%;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 47px;
        transition: 1s ease;
    }

    .calendar-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        h6 {
            display:flex;
            align-items: center;
            margin: 0px;
            color: $iln-dark;
            font-family: LatoWebBold;
            font-size: 14px;
            padding: 0px 10px;
        }

        h6:hover {
            cursor: pointer;
            background-color: #e2f1ee;
            border-radius: 5px;
            padding: 0px 10px;
        }
    }

    .rbc-calendar {
        height: 90%;

        a {
            color: $iln-dark !important;
            font-size: 14px;
            font-family: LatoWebBold;
        }

        .rbc-event {
            font-size: 14px;
            font-family: LatoWeb;
        }

        .rbc-header, .rbc-toolbar-label, .rbc-time-slot, button, .rbc-agenda-content, .rbc-continues-prior {
            color: $iln-dark;
            font-family: LatoWebBold;
            font-size: 14px;
        }

        .rbc-event-label {
            display: none;
        }

        .rbc-event-content {
            div {
                height: 100%;
            }
        }
    }
}

.iln-calendar-event-container {
    margin-top: 10px;
    padding: 20px;
    border-radius: 4px;
    background-color: $white;
    max-width: 500px;
    margin-bottom: 30px;

    .MuiTableCell-body {
        border-bottom: none;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-top: 0px;
    }

    .MuiTableRow-root {
        height: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .MuiTableCell-root {
        height: 100%;
    }

    .icon-column {
        width: 5% !important;
        padding-right: 10px;
        margin-top: 5px;
    }

    .box-column {
        padding: 0px !important;
    }

    .info-column {
        width: 100%;

        h2 {
            color: $iln-dark;
            font-family: LatoWeb;
            font-size: 20px;
            margin-bottom: 5px;
        }

        p {
            color: $iln-dark;
            font-family: Helvetica;
            font-size: 14px;
            font-weight: regular;
            font-weight: 500;
            margin-top: 2px;
        }
    }

    .event-box-icon {
        height: 15px;
        width: 15px;
        border-radius: 4px;
        background-color: $iln-primary;
        margin: 0px;
    }
}