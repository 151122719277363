.slack .focus-areas {
    min-height: 0px;
    box-sizing: border-box;
    border: none;
    padding: 0px;
    margin: 0px;
    font-family: inherit;
    background: #FFFFFF;
    box-shadow: none;
    border-radius: 0px;
    justify-content: inherit;
    flex-direction: column !important;
}


.slack .focus-areas .insights{
    width:100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    margin-top: 30px;
}

.slack .focus-areas .insights .insight{
    float: left;
    height: 100%;
    display: inline-block;
    width: 30%;
    padding: 10px;
}

.slack .focus-areas .insights .insight .number{
    text-align: center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    color: #6221EA;
    margin-bottom: 13px;
}


.slack .focus-areas .insights .insight .message{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: center;
    padding:0 5px;
}

.slack .focus-areas .highlighted {
    color: #6221EA;
}
