.iln-home-icons-flex {
    display: flex;
    height: 100%;
    margin-left: 40px;
   
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 1272px) {
        flex-direction: column-reverse;
    }
}
