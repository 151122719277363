.legend {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &__company,
    &__industry-average,
    &__na {
        margin-right: 10px;
    }


    &__company {
        color: #6221EA;
    }
    &__industry-average {
        color: #1032E4;
        text-transform: capitalize;
    }
    &__na {

    }

    &__na::before,
    &__industry-average::before,
    &__company::before {
        content: '';
        height: 10px;
        width: 10px;
        border-radius: 100%;
        float: left;
        margin-right: 5px;
        margin-top: 2px;
    }

    &__company::before {
        background: #6221EA;
    }
    &__industry-average::before {
        background: #1032E4;
    }
    &__na::before {
        background: rgba(237, 237, 238, 0.5);
        border: 1px dashed rgba(50,35,82,0.38);
    }
}
