@import 'variables';

/* Font Weights */
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;

/* Font Family */
/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWebLight';
  src: url('/assets/fonts/Lato/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/Lato/Lato-Light.woff2') format('woff2'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Light.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: $light;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  src: url('/assets/fonts/Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/Lato/Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Regular.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: $regular;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoWebMedium';
  src: url('/assets/fonts/Lato/Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato/Lato-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/Lato/Lato-Medium.woff2') format('woff2'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Medium.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: $medium;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'LatoWebSemibold';
  src: url('/assets/fonts/Lato/Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/Lato/Lato-Semibold.woff2') format('woff2'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Semibold.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: $semiBold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWebBold';
  src: url('/assets/fonts/Lato/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('/assets/fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/Lato/Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Bold.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: $bold;
  text-rendering: optimizeLegibility;
}

// screen to font scale with different viewports
//Screen size   1vw	    2vw	    3vw	    4vw	    5vw
//400px	        4px	    8px	    12px	16px	20px
//500px	        5px	    10px	15px	20px	25px
//600px	        6px	    12px	18px	24px	30px
//700px	        7px	    14px	21px	28px	35px
//800px	        8px	    16px	24px	32px	40px

/* Font Variants */
h1,
.h1-headline {
  font-family: 'LatoWebMedium';
  font-size: 1.5rem;
  margin: 0;
}

h2,
.h2-headline {
  font-family: 'LatoWebMedium';
  font-size: 1.25rem;
  margin: 0;
}

h3,
.subtitle-1 {
  font-family: 'LatoWebSemibold';
  font-size: 1rem;
  margin: 0;
}

h4,
.subtitle-2 {
  font-family: 'LatoWebSemibold';
  font-size: 0.88rem;
  margin: 0;
}

h5,
.caption {
  font-family: 'LatoWeb';
  font-size: 0.75rem;
  margin: 0;
}

h6 .overline {
  font-family: 'LatoWeb';
  font-size: 0.63rem;
  margin: 0;
}

p .body-1 {
  font-family: 'LatoWeb';
  font-size: 1rem;
}

small {
  font-size: 80%;
}

button,
input[type='button'] {
  font-family: 'LatoWebBold';
  font-size: 0.88rem;
}

.link {
  font-family: $medium !important;
  font-size: 0.88rem;
  color: $link !important;
  text-decoration: none !important;
}

/* Font faces for Plus Jakarta Sans */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLight.ttf');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-Italic.ttf');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/PlusJakartaSans/PlusJakartaSans-ExtraBoldItalic.ttf');
}

/* Font faces for Lato */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Lato/Lato-Thin.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Lato/Lato-ThinItalic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Lato/Lato-Light.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Lato/Lato-LightItalic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Lato/Lato-Regular.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/Lato/Lato-Italic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Lato/Lato-Bold.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Lato/Lato-BoldItalic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Lato/Lato-Black.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Lato/Lato-BlackItalic.ttf');
}

/* Font faces for Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Inter/Inter-Thin.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Inter/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Inter/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Inter/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Inter/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Inter/Inter-Black.ttf');
}

/* Font faces for Noto Sans */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/NotoSans/NotoSans-Thin.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/NotoSans/NotoSans-ThinItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/NotoSans/NotoSans-ExtraLight.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/NotoSans/NotoSans-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/NotoSans/NotoSans-Light.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/NotoSans/NotoSans-LightItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/NotoSans/NotoSans-Regular.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/NotoSans/NotoSans-Italic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/NotoSans/NotoSans-Medium.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/NotoSans/NotoSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/NotoSans/NotoSans-SemiBold.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/NotoSans/NotoSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/NotoSans/NotoSans-Bold.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/NotoSans/NotoSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/NotoSans/NotoSans-ExtraBold.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/NotoSans/NotoSans-Black.ttf');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/NotoSans/NotoSans-BlackItalic.ttf');
}
