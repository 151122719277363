.gender-summary{
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    height: 100%;
    border-right: 1pt solid lightgrey;
}

.gender-summary .recharts-wrapper{
    display: inline-block;
    float:left;
    margin-top: 0px;
}



.content-summary{
    height:250px;
}