
.slack .card {
    margin-bottom: 60px;
}

.slack .filters > button {
    display: inline-block;
    background-color: #6221EA;
    color: white;
    padding:10px 30px;
    border:none;
    border-radius: 4px;
    margin-left: 20px;
    vertical-align: bottom;
}

.slack h2{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.slack h3{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 27px;
}



.slack .content-summary{
    margin-top:37px;
}

.slack p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140.62%;
    color: #66686A;
}