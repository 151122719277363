@import '../../../../styles/variables';
@import '../../../../styles/layout';

@keyframes show-legend-text {
        from {opacity: 0;}
        to {opacity: 1;}
}

.iln-calendar-legend-card {
        height: 100%;
        width: 17%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        
        .iln-legend-item {
                display: flex;
                align-items: center;
                padding-bottom: 20px;
                width: 80%;
                
                .dot {
                        height: 10px;
                        width: 10px;
                        min-width: 10px;
                        min-height: 10px;
                        max-width: 10px;
                        max-height: 10px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 10px;
                }

                p {
                        margin: 0;
                        text-align: left;
                        word-wrap: break-word;
                        color: $iln-dark;
                        overflow: hidden;
                        font-size: 14px;
                        font-family: LatoWebBold;
                }
        }

        .legend-animation-open-text {
                opacity: 0;
                animation-name: show-legend-text;
                animation-duration: 2s;
                animation-delay: 570ms;
                animation-fill-mode: forwards;
        }

        .legend-animation-close-text {
                display: none;
        }  
}

.legend-animation-open {
        width: 17%;
        padding-left: 20px; 
        padding-right: 20px;
        transition: 1s ease;
}

.legend-animation-close {
        width: 0px;
        padding-left: 0px; 
        padding-right: 0px;
        transition: 1s ease;
}