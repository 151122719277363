.form {
    .formControl {
        margin: 0 0 18px;
    }

    .flex {
        justify-content: space-between;
        max-width: 400px;
    }

    .save-button {
        margin-top: 24px;
    }
}