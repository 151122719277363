.archived-solutions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: calc(100% - 60px);

  &.no-data {
    height: 100%;
  }

  .table-body-container {
    width: 81%;
    height: 100vh;
    padding: 32px 0;
    
    .solution-cell {
      margin-bottom: 10px;
    }

    .programs {
      display: flex;
      justify-content: center;
    }
  }

  .pagination-footer-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;

    .MuiTablePagination-root {
      width: 100%;
    }

    .MuiTablePagination-toolbar {
      padding: 14px 24px 12px 32px;
    }
  }

  .archived-solutions-toast-component {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 1;
    animation: 0.4s ease-out 0s 1 slideInFromLeft;
  }
}
