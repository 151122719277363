@import '../../../../styles/variables';
@import '../../../../styles/typography';

.diversity-snapshot-detail {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;

    &__title  {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $primary;
        font-size: 1rem;
        font-family: 'LatoWebSemibold';
        padding-right: 2rem;
    }

    &__bullets {
        flex: 4;
        color: $secondary;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1rem;
        font-family: 'LatoWeb';

        li {
            margin-bottom: 1rem;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &__end-spacer {
        flex: 1;
    }

    &__detail-row {
        width: 240px;
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
    }

    &__ndg-breakdown {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        padding-bottom: 0rem;
        margin-bottom: 1rem;

        ul {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            margin-bottom: 0 !important;
            margin-top: 0;
            padding-left: 0;
            padding-bottom: 0;
        }
    }

    &__ndg-list-wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem !important;
    }

    &__ndg-breakdown-item {
        display: flex;
        flex-direction: row;
        width: 240px;
        align-items: center;
    }

    &__ndg-breakdown-name,
    &__detail-name {
        flex: 3;
        font-family: 'LatoWeb';
        font-size: 0.88rem;
        color: $secondary;
    }

    &__ndg-breakdown-number,
    &__detail-number {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        font-family: 'LatoWebSemibold';
        font-size: 0.88rem;
        color: $secondary;

        &.gray {
            width: 25px;
            height: 4px;
            background: url(/assets/images/nid.svg) no-repeat right;
        }
    }
}
