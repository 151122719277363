@import '../../../../styles/variables';
@import '../../../../styles/typography';

@include recharts-tooltip-override;

.employees-surveyed {

    &__pichart .recharts-responsive-container {
        height: 215px !important;
    }

    &__total-label {
        color: $secondary;
        font-size: 16px;
        font-weight: $regular;
    }

    &__total-text {
        color: $secondary;
        font-size: 12px;
    }
}
