@import "../../styles/typography";
@import "../../styles/variables";
@import "../../styles/layout";

// TODO:: @sahanarula Remove importants after complete migration from MUI -> Bootstrap
.App {
  .bespoke-nav {
    margin: 18px 10px 55px !important;
  
    @include navtabs;
  }
  
  .column.grow-1 {
    width: 100%;
  }

  .analyze-title {
    padding-top: 40px;
  }
}

