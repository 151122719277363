@import '../../../styles/variables';
@import '../../../styles/typography';
@import '../../../styles/layout';
@import '../styles/';

.inclusion-score {
    min-height: $min-card-height;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        flex: 1;
        // height: calc(100% - #{$card-padding-vertical});
        text-align: left;
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    &__card-title {
        color: $primary;
        opacity: $opacity-primary;
    }

    &__circle {
        border-radius: 50%;
        border: 3px solid $primary;
        width: 121px;
        height: 121px;
        margin-top: 60px;
        margin-bottom: 32px;  
        box-sizing: border-box;
    
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__score-text {
        font-weight: $semiBold;
        font-size: 34px;
        line-height: 41px;
    
        color: $secondary;
        mix-blend-mode: normal;
        opacity: $opacity-primary;
    }

    &__score-number {
        color: $primary;
    }

    &__comparisons-trends-container {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
    }

    &__comparison-text,
    &__trend-text {
        flex: 1;
        color: $secondary;
        opacity: $opacity-primary;
        font-size: 0.75rem;
    }
}

.inclusion-score .trend.positive:before,
.inclusion-score .comparison.positive:before,
.inclusion-score .trend.negative:before,
.inclusion-score .comparison.negative:before {
    content: '';
    float: left;
    width: 10px;
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
    
}

.inclusion-score .trend.positive:before,
.inclusion-score .comparison.positive:before {
    background: url("/assets/images/plus.svg") no-repeat 0px 6px;
}

.inclusion-score .comparison.negative:before,
.inclusion-score .trend.negative:before {
    background: url("/assets/images/minus.svg") no-repeat 0px 6px;
}
