.diversity-dashboard{
    max-width: 1080px;
    min-width: 920px;
    margin: 0 auto;
}

.diversity-dashboard > *{
    margin-bottom: 40px;
}

.diversity-dashboard .graph {
    text-align: center;
    display: inline-block;
}

.diversity-dashboard .info {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width:270px;
    width:430px;
    display: inline-block;
    vertical-align: top;
}

.diversity-dashboard .header-info{
    width:100%;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
}

.diversity-dashboard .header-info .left{
    display: inline-block;
    width: 60%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 19px;
    color: #66686A;
    vertical-align: bottom;
}

.diversity-dashboard .header-info .right{
    /*float:right;*/
    position: relative;
    width: 40%;
    right: 0;
    box-sizing: border-box;
    display: inline-block;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #222;
    vertical-align: bottom;
}

.diversity-dashboard .header-info .right > *{
    text-align: right;
    float: right;
}

.diversity-dashboard .header-info div > * {
    max-width: 80%;
    overflow:hidden;
    text-overflow: ellipsis;
}

.diversity-dashboard .apply-filter {
    margin: 16px 0px;
    background: #6221EA;
    border-radius: 4px;
    color: white;
    width: 96px;
    height: 36px;
    border:none;
    cursor: pointer;
}
