@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-assets{
    background-color: $white;
    border-radius: 4px;
    border-style: solid;
    border-color: $iln-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 98%;
    margin-left: 0 !important;

    @media only screen and (min-width: 1272px) {
        margin-right: 40px;
    }
}

.icon-iln-asset-sum {
    height: 102px;
    width: 166px;
    margin: 8px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/assets_managed.svg");
  }
