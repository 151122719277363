@import '../../../../../styles/variables';
@import '../../../../../styles/layout';

.iln-initiatives-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
    padding-top: 20px;

    .iln-initiative-toprow {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .iln-initiative-pagination{
            display: flex;
            justify-content: space-between;
            padding-left: 25px;
        
            .MuiTypography-root, .MuiSelect-root {
                color: $iln-dark;
                font-family: LatoWebSemiBold;
                font-size: 14px;
            }

            .iln-initiative-theme-title {
                h3 {
                    margin-top: 15px;
                    font-family: LatoWebSemiBold;
                    font-size: 14px;
                    color: $iln-dark;
                }
            }
        }

        .iln-program-info {
            margin-left: 25px;
            margin-top: 20px;
            margin-bottom: 30px;
            text-align: left;
    
            h4 {
                color: $iln-primary;
                font-family: LatoWebSemiBold;
                font-weight: medium;
                font-size: 14px;
            }
    
            p {
                color: $iln-dark;
                font-family: LatoWeb;
                font-size: 14px;
                margin-right: 5px;
            }

            .members {
                display: flex;
                
            }

            
        }
    }

    .MuiButton-text {
        margin-bottom: 30px;
    }

    .MuiButton-label {
        height: 100%;
    }

    .MuiTable-root {
        td {
            border-right: none !important;
        }
    }

    th {
        display: flex;
        flex-direction: column;
    }

    .solution-title-active {
        width: 100%;
        height: 120%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px 0px 0px 4px;
        border-left: 8px solid $iln-primary;
        box-sizing: border-box;
        font-family: LatoWebSemiBold;
        font-size: 12px;
        color: $iln-dark;
        padding: 10px;
        text-align: center;

        @media only screen and (max-width: 1100px) {
            height: 160%;
            padding: 0px;
        }
    }
    
    .solution-title {
        width: 100%;
        height: 120%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        box-sizing: border-box;
        font-family: LatoWebSemiBold;
        font-size: 12px;
        color: $iln-dark;
        padding: 10px;
        text-align: center;

        @media only screen and (max-width: 1100px) {
            height: 160%;
            padding: 0px;
        }
    }
    
    .solution-button {
        font-style: normal;
        font-weight: normal;
        text-transform: none;
        float: left;
        height: 71px;
        width: 100%;
        max-width: 260px;
        flex-shrink: 0;
        margin-top: 20px;
    }
}

.iln-h2 {
    padding-left: 100px !important;
}

.no-initiative-found {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    h1 {
        font-family: LatoWebBold;
        font-size: 30px;
        color: $iln-dark;
    }
}
