.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background: #f4f5f8;

  img {
    width: 30px;
  }
}

.loader-inline {
  background: transparent;

  img {
    width: 30px;
  }
}
