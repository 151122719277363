.inclusion-breakdown {
    &__x-axis {
        color: #525A64;
    }

    &__bar-chart-area {
        // hide top-most line on bar chart
        .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
            stroke-opacity: 0;
        }
    }
}

#bar-chart {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 45% 10% 45%;
    grid-template-rows: auto;
    grid-template-areas:
      "a l b";
}

#bar-chart .group-a {
    grid-area: a;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px rgba(35,36,37, 0.2) solid;
}

#bar-chart .group-a .score {
    padding: 0 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 14px;
}

#bar-chart .group-a-hover {
    grid-area: a;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px rgba(35,36,37, 0.2) solid;
    background-color: #EDEDEE;
}

#bar-chart .label-container {
    grid-area: l;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#bar-chart .label-container .label {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

#bar-chart .group-b {
    grid-area: b;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px rgba(35,36,37, 0.2) solid;
}

#bar-chart .group-b .score {
    padding: 0 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 14px;
}

#bar-chart .group-b-hover {
    grid-area: b;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px rgba(35,36,37, 0.2) solid;
    background-color: #EDEDEE;
}

#bar-chart .group-a .bar-a,
#bar-chart .group-a-hover .bar-a {
    width: 100%;
    height: 35px;
    background-color: #6221EA;
    border-radius: 40px;
}

#bar-chart .group-a .na-bar-a,
#bar-chart .group-a-hover .na-bar-a,
#bar-chart .group-b .na-bar-b,
#bar-chart .group-b-hover .na-bar-b {
    width: 100%;
    height: 35px;
    background-color: rgba(237, 237, 238, 0.5);
    border-radius: 40px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
}

#bar-chart .group-b .bar-b,
#bar-chart .group-b-hover .bar-b  {
    width: 100%;
    height: 35px;
    background-color: #CFB4F7;
    border-radius: 40px;
}

#bar-chart .label-container .tooltip .label {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

/* Tooltip container */
#bar-chart .label-container .tooltip {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0 8px;

    cursor: default;
}

/* Tooltip text */
#bar-chart .label-container .tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    height: auto;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    left: -39px;
    top: -95px;
}

/* Show the tooltip text when you mouse over the tooltip container */
#bar-chart .label-container .tooltip:hover .tooltiptext {
    visibility: visible;
    width: 150px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

#bar-chart .label-container .tooltip .tooltiptext .tooltip-label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#bar-chart .label-container .tooltip .tooltiptext h5 {
    text-align: center;
    margin: 0;
    padding: 5px 0;
}

#bar-chart .label-container .tooltip .tooltiptext .tooltip-label h6 {
    margin: 0;
    border: none;
    padding: 5px 0;

    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}

#bar-chart .label-container .tooltip .tooltiptext .tooltip-label .tooltip-group-a::before {
    content: '';
    background: #FFC107;
    height: 12px;
    width: 12px;
    float: left;
    border-radius: 100%;
    margin: 0 6px;
}

#bar-chart .label-container .tooltip .tooltiptext .tooltip-label .tooltip-group-b::before {
    content: '';
    background: #03A9F4;
    height: 12px;
    width: 12px;
    float: left;
    margin: 0 6px;
}
