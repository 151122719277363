@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-about {
    display: flex;
    background-color: $iln-primary;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    .iln-text {
        width: 55%;
        text-align: left;

        p {
            font-family: LatoWeb;
            font-size: 14px;
            font-weight: 200;
            line-height: 150.6%;
            color: $white;
        }
    
        a {
            font-family: LatoWeb;
            font-size: 14px;
            color: $white !important;
            text-decoration: underline !important;
        }
    }

    .icon-iln-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
    }
}

.icon-iln-logo {
    background: $white;
    height: 110px;
    width: 163px;
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    mask-image: url("/assets/images/ILN/partners/ilnLogo.svg")
  }
