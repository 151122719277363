@import '../../../styles/variables';
@import '../../../styles/typography';
@import '../../../styles/layout';

// TODO:: @sahanarula move this to icons.scss after sidebar merge
.icon-question-mark {
    background: $black;
    height: 20px;
    width: 20px;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 90%;
    mask-image: url("./question_mark.svg");  
}
.custom-card {
    width: 100%;
    

    &__container {
        // override Material UI styles
        @include card;
        padding: 0;
        text-align: left;
        border-radius: 15px !important;
        box-shadow: $card-box-shadow;
    }

    &__card-header {
        border-bottom: 1px solid rgba(98, 33, 234, 0.38);
        padding: $card-padding-vertical $card-padding-horizontal;

        .MuiCardHeader-action {
            align-self: center;
            margin-top: 0;
            margin-right: 0;
        }
    }
        
    &__card-content {
        font-family: 'LatoWeb';
    }

    [class*='MuiCardContent-root'] {
        padding: $card-padding-vertical $card-padding-horizontal;
    }

    &__title {
        padding-bottom: 0.5rem;
        color: $secondary;
        opacity: $opacity-primary;
        font-size: 1rem;
        font-family: 'LatoWebSemibold';
    }
    
    &__subheader {
        padding-top: 0.5rem;
        color: $secondary;
        opacity: $opacity-other1;
        font-size: 1rem;
        font-family: 'LatoWeb';
    }
    
    &__action {
        margin: 0;
    }

    br {
        display: block;
        content: '';
        margin: 16px 0;
    }
}
