@import '../../../styles/variables';
@import '../../../styles/layout';

.executive-summary {
    @include default-margins;
    display: flex;
    flex-direction: row;
    background-color: $executive-summary-bg;

    &__logo-title-col {
        flex: 0.3;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__title {
        color: $primary;
    }
    &__text-col {
        flex: 0.7;
        padding-left: 24px;
        padding-right: 24px;
        text-align: left;
        font-size: 0.88rem;
        color: $secondary;

        max-height: 200px;
        overflow-y: scroll;

        strong {
            color: $primary;
        }

        li {
            list-style-type: disc;
        }
    }
    &__logo-img {
        margin: 24px;
        max-width:50%;
        max-height: 200px;
    }
}
