@import '../../../styles/variables';
@import '../../../styles/typography';
@import '../../../styles/layout';
@import '../styles/';

.inclusion-score {

    &__card-title-bvp {
        color: $secondary;
        opacity: $opacity-primary;
    }

    &__container {
        justify-content: center;
    }

}
