@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-milestone-container {
    padding-bottom: 50px;

    tr {
        width: 150px;
    }

    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
    }

    .complete {
        background-color: #00E031;
    }
    
    .not-started {
        background-color: red;
    }

    .in-progress {
        background-color: #2167EA;
    }

    .MuiTypography-root, .MuiSelect-root {
        color: $iln-dark;
        font-family: LatoWebSemiBold;
        font-size: 14px;
    }

    .child-row {
        background-color: rgba(39,130,158, 0.2);
    }
}

.iln-milestones {
    margin-top: 50px;
    h2 {
        color: $iln-dark;
        font-family: LatoWebBold;
        font-size: 20px;
        text-align: left;
    }

    h3 {
        color: #222222;
        font-family: LatoWebSemiBold;
        font-size: 14px;
    }
}

.milestones-table {
    padding-left: 0 !important;
}



