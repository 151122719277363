.diversity-dashboard .slack .apply-filter{
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 40px;
}

.slack .filters{
    margin-bottom: 58px;
    height:100%;
    margin-top: 30px;
}

.slack .filters .filter {
    margin-bottom: 30px;
}


.slack .filter > div{
    display: flex;
    align-content: baseline;
}

.slack .filters > h2 {
    width: 100%;
}

.slack .dates > * {
    display: inline-block;
    margin-right: 15px;
}

.slack .dates .date > * {
    display: block;
}

.slack .dates .date span{
    font-size: 12px;
    color: #66686A;
}

.slack .dates .date input[type=date] {
    border: 1pt solid lightgrey;
    border-radius: 4px;
    font-family: inherit;
    padding:5px;
    text-align: center;
}
