.deep-dive .graphs {
    margin-top: 46px;
}

.deep-dive .header-info .left > * {
    max-width: 100%;
}

.deepdive-tooltip {
    background-color: white !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 11pt !important;
    border: 0.5px solid rgba(102, 104, 106, 0.38) !important;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
    text-align: justify !important;
    width: 400px !important;
    padding: 15px !important;
}

.deep-dive .subtitle {
    position: relative;
    height: 30px;
    width: 100%;
    margin-top: 30px;
}

.deep-dive .subtitle > * {
    position: absolute;
}

.deep-dive .subtitle > p {
    margin: 0;
}

.deep-dive .subtitle > div {
    color: #6221EA;
    right: 0px;
}
