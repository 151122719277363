#inclusion-breakdown .overview_title {
    height: 20px;
    text-align: left;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

#inclusion-breakdown .legend {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}

#inclusion-breakdown .legend .legend-group-a {
    padding-right: 10px;
}

#inclusion-breakdown .legend .na {
    padding-right: 10px;
}

#inclusion-breakdown .legend .na::before {
    content: '';
    background: rgba(237, 237, 238, 0.5);
    height: 12px;
    width: 12px;
    float: left;
    border-radius: 100%;
    margin: 4px 6px 0;
    border: 1px dashed rgba(0, 0, 0, 0.2);
}

/* #inclusion-breakdown .legend .legend-group-a::before {
    content: '';
    background: #FFC107;
    height: 12px;
    width: 12px;
    float: left;
    border-radius: 100%;
    margin: 4px 6px 0;
}

#inclusion-breakdown .legend .legend-group-b::before {
    content: '';
    background: #03A9F4;
    height: 12px;
    width: 12px;
    float: left;
    border-radius: 100%;
    margin: 4px 6px 0;
} */

/* STYLING FOR LEGEND INSTEAD OF SCALE */
#scale .group-a .legend-group-a::before {
    content: '';
    background: #6221EA;
    height: 12px;
    width: 12px;
    float: left;
    border-radius: 100%;
    margin: 4px 6px 0;
}

#scale .group-b .legend-group-b::before {
    content: '';
    background: #CFB4F7;
    height: 12px;
    width: 12px;
    float: left;
    border-radius: 100%;
    margin: 4px 6px 0;
}

#scale .group-a,
#scale .group-b {
    padding-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
}

#scale {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 45% 10% 45%;
    grid-template-rows: auto;
    grid-template-areas:
        "a l b";
}

#scale .group-a {
    grid-area: a;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    border-top: 1px rgba(35,36,37, 0.2) solid;
}

#scale .label-container {
    grid-area: l;
    width: 100%;
    height: 75px;
}

#scale .group-b {
    grid-area: b;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-top: 1px rgba(35,36,37, 0.2) solid;
}

#scale .group-a h6,
#scale .group-b h6 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

#scale .label-container .label {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}
