@import '../../../styles/variables';
@import '../../../styles/typography';

.home-heat-map {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    width: 100%;
    text-align: center;

    th, td {
        border: 1px solid rgba(50, 35, 82, 0.28);
        color: $secondary;
        font-size: 0.75rem;
        font-weight: $regular;
    }

    th {
        background-color: rgba(235, 236, 240, 0.68);
        padding: 1rem;
    }

    td {
        font-size: 1rem;
        padding: 0.25rem;
    }

    td .gray::before {
        content: url(/assets/images/nid.svg);
        transform: translateY(-4px);
    }

    td.green {
        background-color: rgba(0,200,83, 0.18);
    }

    td.red {
        background-color: rgba(229,57,53, 0.18);
    }

    &__cell {

    }

    &__cell_container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
    }

    &__cell_side {
        flex: 0.5;
        display: flex;
        justify-content: center;
        background-color: transparent !important;
    }

    &__cell_main {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.gray span {
            width: 25px;
            height: 4px;
            background: url(/assets/images/nid.svg) no-repeat center;
        }
    }

    &__number {
        flex: 1;
        display: flex;
        flex-direction: row;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    &__prev_number {
        flex: 1;
        color: grey;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    &__your_kpi {
        color: $primary !important;
    }

    &__your_kpi_side_header {
        color: $primary !important;
        font-weight: $semiBold !important;

    }


    &__side_header {
        background-color: rgba(235, 236, 240, 0.68);
        font-size: 0.75rem !important;
    }

    &__legend {
        font-size: 0.75rem;
        color: $secondary;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    &__legend_item {
        padding-left: 1rem;
    }

    &__legend_strength::before {
        // content: url('strength.svg');
        content: '';
        height: 12px;
        width: 12px;
        float: left;
        margin-right: 5px;
        margin-top: 2px;
        background-color: rgba(0,200,83, 0.18);
    }

    &__legend_painpoint::before {
        // content: url('painpoint.svg');
        content: '';
        height: 12px;
        width: 12px;
        float: left;
        margin-right: 5px;
        margin-top: 2px;
        background-color: rgba(229,57,53, 0.18);
    }

    &__legend_na::before {
        content: url('/assets/images/nid.svg');
        padding: 0.5rem;
        position: relative;
        top: -0.25rem;
    }

    &__legend_prev::before {
        content: "(XX)";
        padding: 0.5rem;
        color: grey;
    }
}

