.summary-graph{
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    height: 100%;
    text-align: center;

}

.summary-graph .recharts-wrapper{
    display: inline-block;
    margin-top: 0px;
}

.summary-graph > *{
    vertical-align: middle;
}

.summary-graph .pie-legend{
    width: 140px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #66686A;
}

.summary-graph .pie-legend .dot{
    width:10px;
    height: 10px;
    top:5px;
    float: left;
    border-radius: 50%;
    position: relative;
}



.summary-graph .pie-legend li.active{
    opacity: 1;
}

.summary-graph .pie-legend li {
    font-size: 12px;
    opacity: 0.5;
    height:20px;
    width: 100%;
    position: relative;
    color: black;
}

.summary-graph .pie-legend li > *{
    margin-right: 5px;
}

.summary-graph .pie-legend ul {
    width: 100%;
    padding-inline-start: 0;
}

