@import '../../styles/variables';
@import '../../styles/layout';

#account {
    padding: 56px 40px 40px;

    h2 {
        margin-bottom: 24px;
        color: $primary90;
    }

    .content {
        width: 100%;
    }

    .column:first-child{
        margin-right: 20px;
    }
    

    .formControl {
        width: 100%;
    }

    .navbar_list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .navbar_list li {
        height: 32px;
        margin: 0 0 12px;
        border-radius: 4px;
        padding: 0px 14px;
    
        &:last-of-type {
            margin-bottom: 0;
        }
    
        a {
            width: 100%;
            height: 100%;
            letter-spacing: 0.3px;
            color: $primary90 !important;
            text-decoration: none;
            font-size: 14px;
            display: flex;
            align-items: center;
    
            img {
                margin: 0 35px 0 15px;
                background-color: $secondary;
                opacity: 0.68;
                width: 20px;
                height: 18px;
            }
        }
    
        &.selected {
            background-color: $primary20;
    
            a {
                color: $primary90 !important;
    
                img {
                    background-color: $primary;
                }
            }
        }
    
        &.iln-dashboard {
            a {
                color:$iln-dark !important;
    
                img{
                    background-color: $iln-primary !important;
                }
            }
        }
    
        .falseLink {
            margin-left: 24px;
            color: inherit;
            text-decoration: none;
            line-height: 65px;
            opacity: 0.3;
            width: 100px;
            display: inline-flex;
        }
    
        .upgrade-pill {
            display: inline-flex;
            color: #5E76FF;
            border-color: #5E76FF;
        }
    }

    .text-field {
        width: auto;
    }
}
