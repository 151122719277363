@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-member-container {
    text-align: left;
    h2 {
        padding-left: 50px;
        color: $iln-dark;
        font-family: LatoWebBold;
        font-size: 20px;
    }

    h3 {
        color: #222222;
        font-family: LatoWebSemiBold;
        font-size: 14px;
    }
}

.iln-members {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;

    .MuiTypography-root, .MuiSelect-root {
        color: $iln-dark;
        font-family: LatoWebSemiBold;
        font-size: 14px;
    }
}

.styled-tablecell {
        background-color: #F6F6F6;
        color: $iln-dark;
        font-size: 14px;
        font-family: LatoWeb;
        font-weight: bold;
    }

    .iln-member-name {
        font-family: LatoWebBold;
        color: $iln-dark;
        font-size: 14px;
    }

    .iln-cell-style {
        font-family: LatoWeb;
        color: $iln-dark;
        font-size: 14px;

        a {
            font-family: LatoWeb;
            color: $iln-dark !important;
            font-size: 14px;
            text-decoration: underline !important;
        }

        img {
            cursor: pointer;
        }
    }

.iln-table-search {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 50px;

    h3 {
        margin-right: 20px;
        color: $iln-dark !important;
    }

    label.Mui-focused {
        color: $iln-primary;
    }

    label {
        font-family: LatoWeb;
        color: $iln-dark;
        font-size: 14px;
    }

    .MuiOutlinedInput-root {
        & fieldset {
            border-color: $iln-dark;
            opacity: 50% !important;
        }
        &:hover fieldset {
            border-color: $iln-primary;
        }
        &.Mui-focused fieldset {
            border-color: $iln-primary;
            opacity: 100%;
        }
        & input {
            font-family: LatoWeb;
            color: $iln-dark;
            font-size: 14px;
        }
    }

    .MuiFormControl-root {
        fieldset {
            opacity: 0.75 !important;
        }
    }
}


