/* import the necessary Bootstrap files */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "variables";

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
);

/* finally, import Bootstrap */
.bootstrap-override {
  @import "~bootstrap/scss/bootstrap";
}

:root * {
  @extend :root;
}