#business-key-performance-indicator {
    box-sizing: border-box;
    min-height: 280px;
    border: 1px solid #DBD9DB;
    background-color: #FFFFFF;
    box-shadow: -5px 5px 20px 0 rgba(30,30,30,0.03);
    padding: 35px 60px;
    margin: 10px;
}

#business-key-performance-indicator .BKPI-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#business-key-performance-indicator .value {
    font-size: 40px;
    color: #6F0476;
    margin: 25px 0px 20px;
}

#business-key-performance-indicator .description {
    text-align: center;
    height: 38px;
}

#business-key-performance-indicator .BKPI-container .percentage-difference {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 10px;
}

#business-key-performance-indicator .BKPI-container .percentage-difference .up {
    color: #4A90E2;
}

#business-key-performance-indicator .BKPI-container .percentage-difference .down {
    color: #E23636;
}
