@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-card {
    margin-top: 47px;
    margin-right: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 24px;
}

.iln-partners {

  .MuiTableCell-root {
    border-bottom: none;
  }

  h2 {
    padding-left: 50px;
    color: $iln-dark;
    margin-bottom: 20px;
    font-family: LatoWebBold;
    font-size: 20px;
    text-align: left;
  }
}

.icon-partner-aimco {
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/aimCo.svg");
  }

  .icon-partner-allianz {
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/allianz.svg");
  }

  .icon-partner-ardian {
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/ardian.svg");
  }

  .icon-partner-cdpq {
    height: 65px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/cdpq.svg");
  }

  .icon-partner-ssga {
    height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/ssga.svg");
  }

  .icon-partner-cpp {
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/cpp.svg");
  }

  .icon-partner-ninety-one {
    height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/ninety_one.svg");
  }

  .icon-partner-natixis {
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/natixis.svg");
  }

  .icon-partner-omers {
    height: 30px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/omers.svg");
  }

  .icon-partner-teachers {
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/ontarioTeachers.svg");
  }

  .icon-partner-optrust {
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/optrust.svg");
  }

  .icon-partner-psp {
    height: 42px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/psp.svg");
  }

  .icon-partner-nordea {
    height: 25px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/partners/nordea.svg");
  }
