@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-aum-text {
    display: flex;
}

.iln-aum-btn {
    position: relative;

    .MuiButtonBase-root {
        background-color: $iln-primary;
        color: $white;
        padding-left: 20px;
        padding-right: 20px;
        position: absolute;
        bottom: 0;
        margin-bottom: 6px;
        font-family: LatoWeb;
    }
}

.iln-aum-container {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
    padding-left: 50px;
    text-align: left;
}

.iln-aum-h2 {
    padding-left: 50px;
    margin-bottom: 40px;
    color: $iln-dark;
    font-family: LatoWebBold;
    font-size: 20px;
    text-align: left;
}

.h4-aum {
    color: $iln-dark;
    font-family: LatoWebBold;
}

.text-field {
    width: 150px;
}

.iln-aum-barchart {
    padding-left: 50px;
    padding-right: 50px;
    .recharts-default-tooltip {
        border: 2px solid $iln-primary !important;
        border-radius: 4px;
        font-size: 14px;
    }

    .axis-style {
        fill: $secondary;
        font-family: LatoWeb;
        font-size: 14px;
    }

    .iln-aum-loading {
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
