@import '../../../../styles/variables';
@import '../../../../styles/layout';

.iln-impact-card {
    overflow-x: scroll;
}

.iln-impact-h2 {
    color: $iln-dark;
    margin-bottom: 40px;
    font-family: LatoWebBold;
    font-size: 20px;
    text-align: left;
}

.iln-dropdown {
    margin-right: 20px;
    .MuiTypography-root, .MuiSelect-root{
        color: $iln-dark;
        font-family: LatoWeb;
        font-size: 14px;
    }

    .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover {
        border-color: $iln-primary !important;
    }
}

.iln-filter-container {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
}

.iln-filter-text {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.h4-initiative {
    color: $iln-dark;
    font-family: LatoWebSemiBold;
    font-size: 14px;
}

.iln-text-field {
    width: 120px;
}

.iln-filter-btn {
    display: flex;
    align-items: flex-end;
    margin-left: 40px;

    .MuiButtonBase-root {
        font-family: LatoWeb;
    }
}

.iln-filter-enter {
    background-color: $iln-primary;
    color: $white;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 6px;
}

.initiative-container {
    padding: 40px 25px;

    .initiative-child-table{
        width: 100%;
        
        tbody {
            display: flex !important;
        }
        
        .initiative-child-tr {
            
            min-height: 140px !important;
        }

        .initiative-child-th {
            display: flex;
            justify-content: flex-start;
            margin-left: auto;
            text-align: left;
            padding: 20px;

            h4 {
                text-align: left;
            }

            ul {
                list-style: none;
              }
              
              ul li::before {
                content: "\2022";
                color: $iln-primary;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
              }

              li {
                  .p-desc {
                      margin-top: -15px;
                  }
              }
        }
    }
}

.communication-disabled{
    color: $iln-dark !important;
    opacity: 0.58 !important;
}

.initiative-info {
    background-color: #EBECF0;
    padding: 20px;

    .initiative-info-title {
        text-align: left;
    }

    .title-h3 {
        color: $iln-primary;
        font-family: LatoWebSemiBold;
        font-size: 14px;
    }
    
    .title-desc {
        font-family: LatoWeb;
        font-size: 14px;
        color: $iln-dark;
    }

    .p-style {
        margin-right: 15px;
        color: $iln-primary;
        text-decoration: underline;
        font-family: LatoWebBold;
        font-size: 14px;
    }

    .p-desc {
        color: $iln-dark;
        font-family: LatoWeb;
        font-size: 14px;
        text-transform: capitalize;
    }

    h4 {
        color: $iln-primary;
        font-family: LatoWebSemiBold;
        font-weight: medium;
        font-size: 14px;
    }
}

.initiative-links {
    display: flex;
    margin-bottom: 15px;
}

.iln-datatable-container {
    // overflow-x: auto;
    max-width: 100%;
    .custom-hover-tooltip-popper .custom-hover-tooltip {
        border: 2px solid $iln-primary !important;
        font-family: LatoWebBold;
        color: $iln-dark;
        &--arrow {
            &:before {
                border: 2px solid $iln-primary;
            }
        }
    }
    
    .datatable {
        width: 100%;
        
        td:first-child, th {
            font-weight: 500;
            font-size: 14px;
            color: $iln-dark;
        }
        tbody{
            display:block;
            width: 100%;
            border: 2px #DADADA;
            border-collapse: separate;
            border-spacing: 4px; 
        }
    
        .iln-initiative-table {
            box-sizing: border-box;
            border-style: none solid solid;
        }
    
        tr {
            display: flex;
            width: 100%;
        }
    
        th {
            background-color: rgba(235, 236, 240, 0.68);
        }
    
        th, td {
            width: 100px;
            min-height: 70px;
            padding: 0.25rem;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            border-bottom: 1px solid rgba(50, 35, 82, 0.28);
            border-right: 1px solid rgba(50, 35, 82, 0.28);
            text-align: center;
        }
    
        th {
         border: 1px solid rgba(50, 35, 82, 0.28);
         padding-left: 20px;
         padding-right: 20px;
        }
    
        &-filters {
            width: 100% !important;
            th {
                position: relative;
                min-height: 50px;
                border-right: none;
                border-left: 1px solid rgba(50, 35, 82, 0.28);
                border-bottom-color: transparent;
                color: $iln-dark;
                opacity: 0.88;
                cursor: pointer;
                border-top-left-radius: 4px;
                font-family: LatoWebBold;
                font-size: 12px;
    
                &.selected {
                    opacity: 1;
                    background: $iln-primary;
                    color: white;
                }
    
                &:before,
                &:after {
                    content: '';
                    border-right: 25px solid white;
                    border-bottom: 50px solid transparent;
                    border-top-left-radius: 4px;
                    position: absolute;
                    width: calc(100%);
                    height: 100%;
                }
            }
        }
    
        &-border {
            height: 6px;
            background: $iln-primary;
            margin-right: 1px;
        }
    
        .empty-cell {
            border: none;
            background: none;
            pointer-events: none;
    
            &:before,
            &:after {
                border: none;
            }
        }
    }
}
