.chip{
    padding: 0 20px;
    /*margin: 5px 5px;*/
    /*margin-bottom: 10px;*/
    height:30px;
    background-color: white;
    border: 2px solid #CFB4F7;
    box-sizing: border-box;
    border-radius: 50px;
    text-align: center;
    margin-right: 15px;
    margin-bottom: 9px;
}

.chip > * {
    top:2px;
}

.chip > img {
    position: relative;
    /*transform: translateY(-50%);*/
    /*top:50%;*/
    display: inline-block;
    height: 8px;
    width: 8px;
    cursor:pointer;
    margin-left: 5px;
}

.chip > span {
    position: relative;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.chip.focused{
    background-color: #cfb4f7;
}

/*const pieColors = ['#6221EA', '#cfb4f7', ''];*/
