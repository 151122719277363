@import "../../../../styles/variables";

.heatmap-snapshot {
  border: 1px solid $primary;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 40px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 26px 0;

  &__spinner {
    padding: 0.5rem;
  }

  &__prev-page,
  &__next-page {
    color: $link;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(1.5);

    &-icon {
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
  }

  &__icon-disabled {
    color: $grey;
    cursor: default;
  }

  &__icon-hidden {
    opacity: 0;
    cursor: default;
  }

  &__titles {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    h6 {
      &.highlight {
        color: $primary;
      }
    }

    .title {
      text-transform: capitalize;
    }
  }

  &__scores {
    hr {
      width: 90%;
    }

    .heatmap-snapshot__score-container,
    .heatmap-snapshot__overview-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;

      p {
        font-size: 12px;
        word-break: break-word;
      }

      .heatmap-snapshot__score {
        border: 2px solid #EBECF0;
        border-radius: 50%;
        width: 88px;
        height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &--current {
          font-weight: bold;
          font-size: 1rem !important;
        }

        &--previous {
          font-weight: bold;
          color: $grey;
          font-size: 1rem !important;
        }

        .insufficient-data {
          width: 25px;
          height: 4px;
          margin: 12px 0;
          background: url(/assets/images/nid.svg) no-repeat center;
        }
      }

      &.heatmap-snapshot__overview-container {
        flex-direction: row;

        .heatmap-snapshot__score {
          width: 120px;
          height: 120px;

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__diff {
    flex-direction: column;
    align-items: flex-start;

    p {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      opacity: 0.87;
      color: #4d4069;

      &.higher {
        &::before {
          content: '';
          height: 10px;
          width: 10px;
          background: url('/assets/images/plus.svg') no-repeat center center;
          position: absolute;
          margin-left: -15px;
          margin-top: 3px;
        }
      }

      &.lower {
        &::before {
          content: '';
          height: 10px;
          width: 10px;
          background: url('/assets/images/minus.svg') no-repeat center center;
          position: absolute;
          margin-left: -15px;
          margin-top: 3px;
        }
      }

      .highlight {
        color: $primary;
      }
    }
  }
}
