@import '../../../styles/variables';
@import '../../../styles/typography';

.diversity_composition {
    &__left_side_wrapper {
        display: flex;
        flex-direction: column;
    }
    &__chart {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__legend {
        margin-top: 2rem;
        font-size: 0.75rem;
    }
}

.recharts-tooltip-cursor {
    fill: #ccc !important;
    fill-opacity: $opacity-other1 !important;
}

.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
    display: none !important;
}
