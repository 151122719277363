@import '../../../styles/variables';
@import '../../../styles/layout';

.iln-powered{
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.icon-iln-powered-by {
    height: 50px;
    width: 89px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/images/ILN/powered.svg");
  }
