#tokens {
    h2 {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #322352;
    }

    p {
        font-family: "LatoWebLight";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 140.62%;
        color: #322352;
    }

    a {
        font-weight: bold;
        color:#6221EA;
        border-bottom: 1pt solid #6221EA;
    }
}