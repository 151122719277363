@import '../../../styles/variables';
@import '../../../styles/typography';

.inclusion-tracker {
    &__detail-box {
        border: 1px solid $primary;
        min-height: 144px;
        margin: 1rem;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;
    }

    &__chart {
        padding: 1rem;
        display: flex;
    }

    &__pre-chart,
    &__kpi-filter {
        padding: 1rem;
    }

    &__subtitle {
        font-size: 0.75rem;
    }
}
