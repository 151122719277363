@import '../../../styles/variables';
@import '../../../styles/typography';

.role-breakdown {
    min-height: 500px;

    &__demographic-filters {
        width: 400px !important;
    }

    &__filter {
        padding: 1rem;
    }

    &__pre-chart {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__chart {
        padding: 1rem;
        display: flex;
    }

    &__subtitle {
        font-size: 0.75rem;
    }

    &__industry-toggle {
        .MuiSwitch-colorSecondary.Mui-checked {
            color: $primary;
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #CFB4F7;
        }
        .MuiTypography-body1 {
            font-family: 'LatoWeb';
            font-size: 0.75rem;
            color: $secondary;
        }
        .MuiIconButton-root:hover {
            background-color: transparent;
        }
    }
}
